import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as RequestSelectors from './request.selectors';
import * as RequestActions from './request.actions';
import { DbService, OrderRequestProperties } from '@sidkik/db';
import { EventsService } from '@sidkik/global';

@Injectable()
export class RequestFacade {
  loaded$ = this.store.pipe(select(RequestSelectors.getRequestLoaded));
  loading$ = this.store.pipe(select(RequestSelectors.getRequestLoading));
  allRequestItems$ = this.store.pipe(select(RequestSelectors.getAllRequests));
  selectedRequest$ = this.store.pipe(select(RequestSelectors.getSelected));
  clearTimeoutError$ = this.store.pipe(
    select(RequestSelectors.getClearTimeoutError)
  );

  constructor(
    private readonly store: Store,
    private eventsService: EventsService,
    private dbService: DbService
  ) {}

  loadRequest() {
    this.store.dispatch(RequestActions.loadRequest());
  }

  addRequest(request: Partial<OrderRequestProperties>) {
    // this.eventsService.addToRequest(RequestActions as OrderRequestProperties);
    this.store.dispatch(
      RequestActions.addRequest({
        request: request as OrderRequestProperties,
      })
    );
  }

  markTimeoutErrorCleared() {
    this.store.dispatch(RequestActions.clearTimeoutErrorCompleted());
  }

  cleanupRequest(request: OrderRequestProperties) {
    this.store.dispatch(RequestActions.clearSelectedRequest());
    this.store.dispatch(
      RequestActions.deleteRequest({
        request,
      })
    );
  }
}
