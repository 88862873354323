import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CartActions from './cart.actions';
import { cartStateKey } from '@sidkik/global';
import {
  CartItemProperties,
  CartProperties,
  CouponProperties,
  PromoCodeProperties,
} from '@sidkik/db';

export const cartFeatureKey = cartStateKey;

export interface State extends EntityState<CartItemProperties> {
  selectedId?: string | number;
  loaded: boolean;
  loading: boolean;
  coupon?: CouponProperties;
  promoCode?: PromoCodeProperties;
  error?: string | null;
  discountedCart?: CartProperties; // return from the coupon check
  checkCouponError?: string | null;
  discountRequiresFuturePaymentMethod?: boolean;
  termsRequired?: boolean;
  terms?: string;
  couponTerms?: string;
  couponTermsRequired?: boolean;
}

export function selectCartItem(p: CartItemProperties): string {
  return p.data.sku.id;
}

export function sortByCartItem(
  a: CartItemProperties,
  b: CartItemProperties
): number {
  return a.data.sku.id.localeCompare(b.data.sku.id);
}

export const adapter: EntityAdapter<CartItemProperties> =
  createEntityAdapter<CartItemProperties>({
    selectId: selectCartItem,
    sortComparer: sortByCartItem,
  });

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,

  on(CartActions.loadCartSuccess, (state, action) =>
    adapter.setAll(action.items, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(CartActions.loadCartFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error,
  })),
  on(CartActions.checkCouponSuccess, (state, action) => ({
    ...state,
    coupon: action.coupon,
    promoCode: action.code,
    discountedCart: action.discountedCart,
    checkCouponError: undefined,
    discountRequiresFuturePaymentMethod:
      action.requiresFuturePaymentMethod ?? false,
  })),
  on(CartActions.checkCouponFailure, (state, action) => ({
    ...state,
    checkCouponError: action.error,
    discountedCart: undefined,
    coupon: undefined,
    promoCode: undefined,
    discountRequiresFuturePaymentMethod: undefined,
  })),
  on(CartActions.clearCoupon, (state, action) => ({
    ...state,
    coupon: undefined,
    promoCode: undefined,
    discountedCart: undefined,
    discountRequiresFuturePaymentMethod: undefined,
  })),
  on(CartActions.loadCart, (state) => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(CartActions.addCartItem, (state, action) =>
    adapter.addOne(action.item, state)
  ),
  on(CartActions.deleteCartItem, (state, action) =>
    adapter.removeOne(action.item.id, state)
  ),
  on(CartActions.removeTerms, (state) => ({
    ...state,
    terms: undefined,
    termsRequired: false,
  })),
  on(CartActions.updateTerms, (state, action) => ({
    ...state,
    terms: action.terms,
    termsRequired: action.termsRequired,
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
