<div class="tw-cdx-block tw-cdx-checklist">
  <div *ngFor="let item of content?.items" class="tw-cdx-checklist__item tw-flex tw-items-start tw-mb-3"
    [class.cdx-checklist__item--checked]="item.checked">
    <div class="tw-cdx-checklist__item-checkbox tw-mr-3 tw-mt-1 tw-text-primary-500">
      <svg *ngIf="item.checked" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="tw-w-6 tw-h-6">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
    <div class="tw-cdx-checklist__item-text" [innerHTML]="item.text"></div>
  </div>
</div>