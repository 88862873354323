import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import {
  Checkbox,
  FormControlType,
  Input as FormInput,
  SelectAttributes,
  SelectComponent,
} from './models/elements';
import { Alignment, Config, FormComponent } from './models/config';
import { BehaviorSubject } from 'rxjs';
import { ComboBoxOption } from '@sidkik/global';

@Component({
  selector: 'sidkik-ui-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [],
})
export class DynamicFormComponent implements OnInit {
  @Input() config: any[] | undefined | null = [];
  @Input() formId = '';
  @Output() formSubmitted: EventEmitter<{ formId: string; payload: any }> =
    new EventEmitter<{ formId: string; payload: any }>();
  form!: FormGroup;
  hasConfig = false;
  sections$: BehaviorSubject<FormComponent[]> = new BehaviorSubject<
    FormComponent[]
  >([]);
  sections: FormComponent[] = [];
  alignment = Alignment;

  fcType = FormControlType;

  constructor() {}

  ngOnInit() {
    logger.debug('sidkik-ui:dynamic-form:init', 'init', this.config);
    if (!this.config || this.config.length === 0) {
      logger.warn('sidkik-ui:dynamic-form:init', 'No config provided');
      return;
    }
    if (!!this.config) {
      this.parseConfig(this.config);
      this.hasConfig = true;
    }
  }

  getAutoCompleteType(type: FormControlType) {
    switch (type) {
      case FormControlType.Email:
        return 'email';
      case FormControlType.Name:
        return 'name';
      default:
        return 'off';
    }
  }

  parseConfig(config: Config[]) {
    const wrapper = config[0]?.frames[0]?.component;
    if (!wrapper) {
      return;
    }

    const wrapperComponents = wrapper.components;
    if (!wrapperComponents) {
      return;
    }

    const formComponent = wrapperComponents[0];
    if (!formComponent) {
      return;
    }

    const formComponents = formComponent.components;
    if (!formComponents) {
      return;
    }

    const formBody = formComponents[0];
    if (!formBody) {
      return;
    }

    const sections = formBody.components as FormComponent[];
    if (!sections) {
      return;
    }

    sections.forEach((section) => {
      section?.components?.forEach((component) => {
        if (component.type === FormControlType.Select) {
          const options = this.getComboboxOptions(component);
          (component as any).options = options;
        }
      });
    });

    this.sections$.next(sections);
    this.sections = sections;

    this.generateFormControls(this.sections);
  }

  getComboboxOptions(component: FormComponent): ComboBoxOption[] {
    const options = (component as unknown as SelectComponent).components;
    if (!options) {
      return [];
    }
    return options.map((option) => {
      return {
        label: option.attributes.label,
        value: option.attributes.value,
      };
    });
  }

  generateFormControls(sections: any) {
    const formControls: any = {};
    sections.forEach((section: any) => {
      section.components.forEach((component: any) => {
        const name = component.attributes.name;
        const type = component.type;
        switch (type) {
          case FormControlType.Name:
          case FormControlType.Email:
          case FormControlType.Input:
          case FormControlType.TextBox:
            formControls[name] = this.generateInputControl(
              component.attributes
            );
            break;
          case FormControlType.Checkbox:
            formControls[name] = this.generateCheckboxControl(
              component.attributes
            );
            break;
          case FormControlType.Select:
            formControls[name] = this.generateSelectControl(
              component.attributes
            );
            break;
          default:
        }
      });
    });
    this.form = new FormGroup(formControls);
  }

  generateInputControl(component: FormInput): FormControl {
    const fControl = new FormControl();
    fControl.setValue('');
    const validators: ValidatorFn[] = [];
    if (component.required) {
      validators.push(Validators.required);
    }
    if (component.inputtype === 'email') {
      validators.push(Validators.email);
    }

    fControl.setValidators(validators);
    return fControl;
  }

  generateCheckboxControl(component: Checkbox): FormControl {
    const fControl = new FormControl(component.checked || false);
    const validators: ValidatorFn[] = [];
    if (component.required) {
      validators.push(Validators.required);
    }

    fControl.setValidators(validators);
    return fControl;
  }

  generateSelectControl(attributes: SelectAttributes): FormControl {
    const fControl = new FormControl();
    fControl.setValue('');
    const validators: ValidatorFn[] = [];
    if (attributes.required) {
      validators.push(Validators.required);
    }

    fControl.setValidators(validators);
    return fControl;
  }

  calculateColspan(colspan = 6) {
    return `sm:tw-col-span-${colspan}`;
  }

  calculateColspanAndTextSize(colspan = 6, textSize = 'normal') {
    let textSizeClass = 'tw-text-base';
    if (textSize === 'smaller') {
      textSizeClass = 'tw-text-sm';
    } else if (textSize === 'larger') {
      textSizeClass = 'tw-text-lg';
    }
    return `sm:tw-col-span-${colspan} ${textSizeClass}`;
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const fv = this.form.value;
      Object.keys(fv).forEach((key) => {
        if (fv[key] === true) {
          fv[key] = 'true';
        }
        if (fv[key] === false) {
          fv[key] = 'false';
        }
      });
      this.formSubmitted.emit({
        formId: this.formId,
        payload: fv,
      });
    } else {
      logger.warn('sidkik-ui:dynamic-form:onSubmit', 'invalid form');
    }
  }
}
