import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

export interface EmbedBlock {
  service: string;
  source: string;
  embed: string;
  width: number;
  height: number;
  caption: string;
}

@Component({
  selector: 'sidkik-editor-js-embed',
  templateUrl: './editor-js-embed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsEmbedComponent {
  @Input() content!: EmbedBlock;
}
