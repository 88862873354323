export interface Section {}

export enum FormControlType {
  Input = 'sidkik-input',
  Email = 'sidkik-email',
  Name = 'sidkik-name',
  Button = 'sidkik-button',
  Select = 'sidkik-select',
  TextBox = 'sidkik-text-area',
  Checkbox = 'sidkik-checkbox',
  Divider = 'sidkik-divider',
  Text = 'sidkik-text',
}

export enum InputType {
  Text = 'text',
  Email = 'email',
  Number = 'number',
}

export interface Input {
  inputtype: string;
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
  colspan: number;
}

export interface Checkbox {
  value: string;
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
  colspan: number;
  checked: boolean;
}

export interface SelectComponent {
  attributes: SelectAttributes;
  components: { type: string; attributes: { label: string; value: string } }[];
}

export interface SelectAttributes {
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
  colspan: number;
}
export interface Option {
  value: string;
  label: string;
}

export interface Button {
  decoration: string;
  text: string;
  name: string;
  align: string;
}
