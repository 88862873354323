import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

export interface ListBlock {
  style: string;
  items: string[];
}

@Component({
  selector: 'sidkik-editor-js-list',
  templateUrl: './editor-js-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsListComponent {
  @Input() content!: ListBlock;
}
