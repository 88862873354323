import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as RequestActions from './request.actions';
import { requestStateKey } from '@sidkik/global';
import { OrderRequestProperties } from '@sidkik/db';

export const requestFeatureKey = requestStateKey;

export interface State extends EntityState<OrderRequestProperties> {
  selectedId?: string | number;
  loaded: boolean;
  loading: boolean;
  clearTimeoutError: boolean;
  error?: string | null;
}

export const adapter: EntityAdapter<OrderRequestProperties> =
  createEntityAdapter<OrderRequestProperties>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  clearTimeoutError: false,
});

export const reducer = createReducer(
  initialState,

  on(RequestActions.loadRequestSuccess, (state, action) =>
    adapter.setAll(action.requests, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(RequestActions.loadRequestFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error,
  })),
  on(RequestActions.clearTimeoutError, (state) => ({
    ...state,
    clearTimeoutError: true,
  })),
  on(RequestActions.clearTimeoutErrorCompleted, (state) => ({
    ...state,
    clearTimeoutError: false,
  })),
  on(RequestActions.loadRequest, (state) => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(RequestActions.addRequest, (state, action) =>
    adapter.addOne(action.request, state)
  ),
  on(RequestActions.clearSelectedRequest, (state) => ({
    ...state,
    selectedId: undefined,
  })),
  on(RequestActions.attemptPayment, (state, action) =>
    adapter.updateOne(action.request, {
      ...state,
      selectedId: action.request.id,
    })
  ),
  on(RequestActions.deleteRequest, (state, action) =>
    adapter.removeOne(action.request.id, state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
