import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SHOP_FEATURE_KEY, ShopState } from './shop.reducer';
import { authzStateKey, productStateKey, skuStateKey } from '@sidkik/global';
import {
  State as ProductState,
  selectAll as selectAllProducts,
} from './product/product.reducer';
import { getSelectedId as getSelectedProductId } from './product/product.selectors';
import {
  State as SKUState,
  selectAll as selectAllSKUs,
} from './sku/sku.reducer';
import {
  CartItemProperties,
  ProductProperties,
  ProductSubType,
  Purchasable,
  PurchasableProperties,
} from '@sidkik/db';
export const getAuthzState = createFeatureSelector<any>(authzStateKey);

export const getLoggedIn = createSelector(
  getAuthzState,
  (state: any) => state.loggedIn
);

export const getUser = createSelector(getAuthzState, (state: any) => {
  if (state.loggedIn) return state.user;
  return null;
});

export const getUserId = createSelector(getAuthzState, (state: any) => {
  if (state.loggedIn) return state.userId;
  return null;
});

export const getMe = createSelector(getAuthzState, (state: any) => state.me);

export const getShopState = createFeatureSelector<ShopState>(SHOP_FEATURE_KEY);

export const getProductState = createSelector(
  getShopState,
  (state: ShopState) => state[productStateKey]
);

export const getSKUState = createSelector(
  getShopState,
  (state: ShopState) => state[skuStateKey]
);

export const getCartItemsLoaded = createSelector(
  getProductState,
  getSKUState,
  (productState: ProductState, skuState: SKUState) =>
    productState.loaded && skuState.loaded
);

const getProductsLoaded = createSelector(
  getProductState,
  (state: ProductState) => state.loaded
);

const getSKUsLoaded = createSelector(
  getSKUState,
  (state: SKUState) => state.loaded
);

export const getAllProducts = createSelector(
  getProductState,
  getProductsLoaded,
  (productState: ProductState, isLoaded: boolean) =>
    isLoaded ? selectAllProducts(productState) : []
);

export const getAllSKUs = createSelector(
  getSKUState,
  getSKUsLoaded,
  (skuState: SKUState, isLoaded: boolean) =>
    isLoaded ? selectAllSKUs(skuState) : []
);

export const getAllPurchasables = createSelector(
  getAllProducts,
  getAllSKUs,
  getSelectedProductId,
  (products, skus): Partial<PurchasableProperties>[] => {
    return products
      .filter((product) => product.data.active)
      .map((product): Partial<PurchasableProperties> | undefined => {
        const linkedSkus = (skus.filter((s) => s.data.active) ?? []).filter(
          (s) => product['id'] === s.data.product
        );
        // fix this for malformed items
        if (linkedSkus && linkedSkus.length) {
          return {
            data: {
              sku: linkedSkus.sort((a, b) => a.data.price - b.data.price),
              product: product ?? ({} as ProductProperties),
            },
          };
        }
        return undefined;
      })
      .filter((item) => item !== undefined) as Partial<PurchasableProperties>[];
  }
);

export const getPlatformPurchasables = createSelector(
  getAllPurchasables,
  (items) =>
    items.filter(
      (item) =>
        item.data?.product?.data.subtype === ProductSubType.Platform ||
        (item.data?.product?.data.subtype === ProductSubType.Bundle &&
          item.data?.product?.data?.linkedPlatforms?.length)
    ) ?? undefined
);

export const getSessionPurchasables = createSelector(
  getAllPurchasables,
  (items) =>
    items.filter(
      (item) =>
        item.data?.product?.data.subtype === ProductSubType.Session ||
        (item.data?.product?.data.subtype === ProductSubType.Bundle &&
          item.data?.product?.data?.linkedSessions?.length)
    ) ?? undefined
);

export const getMembershipPurchasables = createSelector(
  getAllPurchasables,
  (items) =>
    items.filter(
      (item) =>
        item.data?.product?.data.subtype === ProductSubType.Membership ||
        (item.data?.product?.data.subtype === ProductSubType.Bundle &&
          item.data?.product?.data?.linkedMemberships?.length)
    ) ?? undefined
);

export const getCourseAndOrChallengePurchasables = createSelector(
  getAllPurchasables,
  (items) =>
    items.filter(
      (item) =>
        item.data?.product?.data.subtype === ProductSubType.Course ||
        item.data?.product?.data.subtype === ProductSubType.Challenge ||
        (item.data?.product?.data.subtype === ProductSubType.Bundle &&
          (item.data?.product?.data?.linkedCourses?.length ||
            item.data?.product?.data?.linkedChallenges?.length))
    ) ?? undefined
);

export const getSelectedPurchasable = createSelector(
  getAllProducts,
  getAllSKUs,
  getSelectedProductId,
  (products, skus, productId) => {
    const linkedSkus = (skus.filter((s) => s.data.active) ?? []).filter(
      (s) => productId === s.data.product
    );
    const selectedProduct = products.find(
      (product) => product.id === productId
    );
    return {
      data: {
        sku: linkedSkus.sort((a, b) => a.data.price - b.data.price),
        product: selectedProduct ?? ({} as ProductProperties),
      },
    };
  }
);

// export const getShopError = createSelector(
//   getShopState,
//   (state: ShopState) => state.error
// );

// export const getAllShop = createSelector(
//   getShopState,
//   (state: ShopState) => selectAll(state)
// );

// export const getShopEntities = createSelector(
//   getShopState,
//   (state: ShopState) => selectEntities(state)
// );

// export const getSelectedId = createSelector(
//   getShopState,
//   (state: ShopState) => state.selectedId
// );

// export const getSelected = createSelector(
//   getShopEntities,
//   getSelectedId,
//   (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
// );
