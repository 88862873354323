import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as ShopSelectors from './shop.selectors';
import {
  Customer,
  CustomerProperties,
  DbService,
  OrderRequestProperties,
} from '@sidkik/db';
import {
  EntityType,
  IntegrationStatusFinally,
  PathsToMonitor,
} from '@sidkik/global';
import { take } from 'rxjs';

@Injectable()
export class ShopFacade {
  loggedIn$ = this.store.pipe(select(ShopSelectors.getLoggedIn));
  user$ = this.store.pipe(select(ShopSelectors.getUser));
  userId$ = this.store.pipe(select(ShopSelectors.getUserId));
  me$ = this.store.pipe(select(ShopSelectors.getMe));
  allPotentialPurchasables$ = this.store.pipe(
    select(ShopSelectors.getAllPurchasables)
  );
  platformPurchasables$ = this.store.pipe(
    select(ShopSelectors.getPlatformPurchasables)
  );
  membershipPurchasables$ = this.store.pipe(
    select(ShopSelectors.getMembershipPurchasables)
  );
  courseAndOrChallengePurchasables$ = this.store.pipe(
    select(ShopSelectors.getCourseAndOrChallengePurchasables)
  );
  sessionPurchasables$ = this.store.pipe(
    select(ShopSelectors.getSessionPurchasables)
  );
  selectedPurchasable$ = this.store.pipe(
    select(ShopSelectors.getSelectedPurchasable)
  );

  constructor(private readonly store: Store, private dbService: DbService) {}

  requestMonitorForFinally(
    orderRequest: OrderRequestProperties,
    userId: string
  ) {
    this.dbService.monitorDocument(
      EntityType.OrderRequest,
      orderRequest.id,
      PathsToMonitor.IntegrationStatus,
      IntegrationStatusFinally,
      orderRequest.integrations.state.lastSync ?? 0,
      PathsToMonitor.IntegrationUpdatedField,
      60000,
      EntityType.CustomerParent,
      userId
    );
  }

  monitorMeUpdate() {
    this.me$.pipe(take(1)).subscribe((me: Customer) => {
      this.dbService.monitorDocument(
        EntityType.Customer,
        me.id,
        PathsToMonitor.IntegrationStatus,
        IntegrationStatusFinally,
        me.meta.updated,
        PathsToMonitor.MetaUpdatedField
      );
    });
  }
}
