import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

export interface CodeBlock {
  language: string;
  code: string;
  caption: string;
}

@Component({
  selector: 'sidkik-editor-js-code',
  templateUrl: './editor-js-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsCodeComponent {
  @Input() content!: CodeBlock;
}
