<ng-container [ngSwitch]="content.level">
  <h1 *ngSwitchCase="1" class="tw-ce-header sidkik-h1" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
    [innerHTML]="content.text"></h1>
  <h2 *ngSwitchCase="2" class="tw-ce-header sidkik-h2" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
    [innerHTML]="content.text"></h2>
  <h3 *ngSwitchCase="3" class="sidkik-h3" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
    [innerHTML]="content.text"></h3>
  <h4 *ngSwitchCase="4" class="sidkik-h4" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
    [innerHTML]="content.text"></h4>
  <h5 *ngSwitchCase="5" class="tw-ce-header" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
    [innerHTML]="content.text"></h5>
  <h6 *ngSwitchCase="6" class="tw-ce-header" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
    [innerHTML]="content.text"></h6>
</ng-container>