import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface QuoteBlock {
  text: string;
  caption: string;
  alignment: string;
}

@Component({
  selector: 'sidkik-editor-js-quote',
  templateUrl: './editor-js-quote.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsQuoteComponent {
  @Input() content!: QuoteBlock;
}
