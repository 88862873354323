<form *ngIf="hasConfig" class="form" [formGroup]="form" name="form">
  <div class="form-body">
    <div *ngFor="let section of (sections$ | async)" class="form-section">
      <div class="form-section-content">
        @for (component of section.components; track component) {
        @switch (true) {
        @case (component.type === fcType.Input || component.type === fcType.Email || component.type === fcType.Name) {
        <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)">
          <label [for]="component.attributes.name" class="input-label">{{component.attributes.label}}</label>
          <div class="">
            <input [type]="component.attributes['inputtype']" [placeholder]="component.attributes['placeholder']"
              [formControlName]="component.attributes.name" [name]="component.attributes.name"
              [id]="component.attributes.name" class="input" [required]="component.attributes.required"
              [autocomplete]="getAutoCompleteType(component.type)">
            <sidkik-input-error [touched]="form.controls[component.attributes.name].touched"
              [dirty]="form.controls[component.attributes.name].dirty"
              [errors]="form.controls[component.attributes.name].errors">
            </sidkik-input-error>
          </div>
        </div>
        }
        @case(component.type === fcType.Divider) {
        <div class="tw-pt-4" [ngClass]="calculateColspan()">
          @if (component.attributes.label && component.attributes.label !== '') {
          <div class="tw-relative">
            <div class="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
              <div class="tw-w-full tw-border-t tw-border-gray-300"></div>
            </div>
            <div class="tw-relative tw-flex tw-justify-center">
              <span class="tw-bg-white tw-px-2 tw-text-sm tw-text-gray-500">{{component.attributes.label}}</span>
            </div>
          </div>
          }
          @else {
          <div class="tw-relative">
            <div class="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
              <div class="tw-w-full tw-border-t tw-border-gray-300"></div>
            </div>
          </div>
          }

        </div>
        }
        @case (component.type === fcType.Text) {
        <div class="tw-whitespace-pre-line -tw-mb-8"
          [ngClass]="calculateColspanAndTextSize(component.attributes.colspan, component.attributes.textSize)"
          [innerHTML]="component.attributes.content">
        </div>
        }
        @case (component.type === fcType.Select) {
        <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)">
          <label [for]="component.attributes.name" class="input-label">{{component.attributes.label}}</label>
          <div>
            <sidkik-combo-box [options]="component.options" [multiSelect]="false"
              [formControlName]="component.attributes.name">
            </sidkik-combo-box>
            <sidkik-input-error [touched]="form.controls[component.attributes.name].touched"
              [dirty]="form.controls[component.attributes.name].dirty"
              [errors]="form.controls[component.attributes.name].errors">
            </sidkik-input-error>
          </div>
        </div>
        }
        @case (component.type === fcType.TextBox) {
        <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)">
          <label [for]="component.attributes.name" class="input-label">{{component.attributes.label}}</label>
          <div>
            <textarea rows="2" [formControlName]="component.attributes.name" name="caption" id="caption"
              class="input"></textarea>
            <sidkik-input-error [touched]="form.controls[component.attributes.name].touched"
              [dirty]="form.controls[component.attributes.name].dirty"
              [errors]="form.controls[component.attributes.name].errors">
            </sidkik-input-error>
          </div>
        </div>
        }
        @case (component.type === fcType.Checkbox) {
        <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)">
          <div>
            <div class="tw-flex tw-items-center">
              <input type="checkbox" [placeholder]="component.attributes['placeholder']"
                [formControlName]="component.attributes.name" [name]="component.attributes.name"
                [id]="component.attributes.name"
                class="tw-mr-2 tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-primary-600 focus:tw-ring-primary-600 !tw-bg-primary-500"
                [required]="component.attributes.required">
              <label [for]="component.attributes.name" class="input-label">{{component.attributes.label}}</label>
            </div>
            <sidkik-input-error [touched]="form.controls[component.attributes.name].touched"
              [dirty]="form.controls[component.attributes.name].dirty"
              [errors]="form.controls[component.attributes.name].errors">
            </sidkik-input-error>
          </div>
        </div>
        }
        @case (component.type === fcType.Button) {
        <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)">
          <div class="tw-flex" [class.tw-justify-start]="component.attributes.align === alignment.Left"
            [class.tw-justify-end]="component.attributes.align === alignment.Right"
            [class.tw-justify-center]="component.attributes.align === alignment.Center">
            <button type="submit" [ngClass]="component.attributes.decoration"
              (click)="onSubmit()">{{component.attributes['text']}}</button>
          </div>
        </div>
        }
        }
        }
      </div>
    </div>
  </div>
</form>
<div *ngIf="!hasConfig">Unable to load form:</div>