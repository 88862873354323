<li class="tw-flex tw-py-6 tw-border-b tw-border-gray-200">
  <div class="tw-flex-shrink-0">
    <sidkik-asset [attr.data-testid]="testIdPrefix + '-image'" [asset]="cartItem?.data?.product?.data?.image"
      class="!tw-relative tw-block tw-h-24 tw-w-24 tw-rounded-md tw-object-cover tw-object-center sm:tw-h-32 sm:tw-w-32"
      preset="med_square"></sidkik-asset>
  </div>

  <div class="tw-ml-4 tw-flex tw-flex-1 tw-flex-col sm:tw-ml-6">
    <div>
      <div class="tw-flex tw-justify-between">
        <h4 class="tw-text-sm tw-w-full">
          <span [attr.data-testid]="testIdPrefix + '-name'"
            class="tw-font-medium tw-text-gray-700 hover:tw-text-gray-800">{{
            cartItem?.data?.product?.data?.name}}</span>
          @if ((cartItem?.data?.discountApplication?.indexOf('Terms:') ?? -1) > -1) {

          <div
            class="tw-my-4 tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
            <div class="tw-p-4">
              <div class="tw-flex tw-items-start">
                <div class="tw-flex-shrink-0">
                  <svg class="tw-h-6 tw-w-6 tw-text-info-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
                  <p class="tw-text-sm tw-font-medium tw-text-gray-900">
                    {{getTerms(cartItem?.data?.discountApplication)[0]}}</p>
                  <p class="tw-mt-1 tw-text-sm tw-text-gray-500">Applicable Terms:
                    {{getTerms(cartItem?.data?.discountApplication)[1]}}</p>
                </div>

              </div>
            </div>
          </div>
          }
        </h4>
        <div>
          <p [attr.data-testid]="testIdPrefix + '-discount'" [class.tw-line-through]="cartItem?.data?.discountAmount"
            class="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">
            {{(cartItem?.data?.sku?.data?.price ?? 0) * .01 | currency}}<span
              class="tw-text-gray-500 tw-font-normal tw-text-sm"
              *ngIf="cartItem?.data?.sku?.data?.type === 'recurring'">/{{cartItem?.data?.sku?.data?.recurring?.period}}</span>
          </p>
          <p [attr.data-testid]="testIdPrefix + '-amount'" *ngIf="cartItem?.data?.discountAmount"
            class="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">
            {{((cartItem?.data?.sku?.data?.price ?? 0) - (cartItem?.data?.discountAmount ?? 0)) * .01 | currency}}<span
              class="tw-text-gray-500 tw-font-normal tw-text-sm"
              *ngIf="cartItem?.data?.sku?.data?.type === 'recurring'">/{{cartItem?.data?.sku?.data?.recurring?.period}}</span>
          </p>
        </div>
      </div>
      @if (cartItem?.data?.appointmentRequest?.startTime){
      <p class="tw-mt-1 tw-text-sm tw-text-gray-500">Appointment: {{(cartItem?.data?.appointmentRequest?.startTime ?? 0)
        * 1000 |
        date: 'full'}}
      </p>
      }
      <p [attr.data-testid]="testIdPrefix + '-caption'" class="tw-mt-1 tw-text-sm tw-text-gray-500">{{
        cartItem?.data?.product?.data?.caption}}</p>
    </div>

    <div class="tw-mt-4 tw-flex tw-flex-1 tw-items-end tw-justify-between">
      <p class="tw-flex tw-items-center tw-space-x-2 tw-text-sm tw-text-gray-700">
        <!-- <svg class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd"
            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
            clip-rule="evenodd" />
        </svg>
        <span>In stock</span> -->
      </p>
      <div class="tw-ml-4">
        <button [attr.data-testid]="testIdPrefix + '-remove-link'" (click)="removeCartItem(cartItem)" type="button"
          class="tw-text-sm tw-font-medium tw-text-primary-600 hover:tw-text-primary-500">
          <span>Remove</span>
        </button>
      </div>
    </div>
  </div>
</li>