import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AppConfig,
  APP_CONFIG,
  SpanTypes,
  TraceService,
  ShopError,
  MOCK_ENABLED,
} from '@sidkik/global';
import { ButtonState, ErrorService } from '@sidkik/ui';

import {
  PaymentMethod,
  StripeAddressElementOptions,
  StripeElementsOptionsMode,
  StripePaymentElementChangeEvent,
  StripeConstructorOptions,
} from '@stripe/stripe-js';
import {
  STRIPE_OPTIONS,
  STRIPE_PUBLISHABLE_KEY,
  StripeAddressComponent,
  StripeFactoryService,
  StripeInstance,
  StripePaymentElementComponent,
} from 'ngx-stripe';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  filter,
  firstValueFrom,
  map,
  take,
  timer,
} from 'rxjs';
import { RequestFacade } from '../../../+state/request/request.facade';
import { ShopFacade } from '../../../+state/shop.facade';
import { CustomerProperties } from '@sidkik/db';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sidkik-checkout-form',
  templateUrl: './checkout-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutFormComponent implements OnChanges, OnDestroy {
  @Input() total = 0;
  @Input() processingState: ButtonState | undefined | null;
  @Input() terms?: string | null;
  @Input() termsRequired: boolean | null = false;
  @Input() requiresFuturePaymentMethod: boolean | null = false;
  @Output() checkoutRequested: EventEmitter<PaymentMethod> =
    new EventEmitter<PaymentMethod>();
  @Output() retryRequested: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild(StripePaymentElementComponent)
  payment!: StripePaymentElementComponent;
  @ViewChild(StripeAddressComponent) address!: StripeAddressComponent;

  styles = getComputedStyle(document.body);
  state$: BehaviorSubject<ButtonState> = new BehaviorSubject<ButtonState>(
    ButtonState.ready
  );

  readyToPurchase$!: Observable<boolean>;
  delayMessage$ = new BehaviorSubject<string>('');

  baseElementOptions: StripeElementsOptionsMode = {
    fonts: [
      {
        cssSrc: 'https://rsms.me/inter/inter.css',
      },
    ],
    appearance: {
      variables: {
        colorPrimary: this.styles.getPropertyValue('--primary-600'),
        colorText: 'rgb(55,65,81)',
        fontSizeBase: '1rem',
        fontWeightBold: '500',
        fontWeightNormal: '500',
        focusOutline: this.styles.getPropertyValue('--primary-500'),
        fontFamily:
          'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      },
      rules: {
        '.Label': {
          display: 'block',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          fontWeight: '500',
          color: 'rgb(55 65 81 / var(--tw-text-opacity))',
        },
        '.Input': {
          boxShadow: '0',
          display: 'block',
          appearance: 'none',
          borderRadius: '0.375rem',
          borderWidth: '1px',
          borderColor: 'rgb(209 213 219)',
          paddingLeft: '0.75rem',
          paddingRight: '0.75rem',
          paddingTop: '0.5rem',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          paddingBottom: '0.5rem',
        },
        '.Input:focus': {
          boxShadow:
            '0px 0px 0px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.02),0 0 0 1px var(--p-colorPrimaryHover)',
        },
      },
    },
  };

  paymentElementOptions: StripeElementsOptionsMode = {
    ...this.baseElementOptions,
    ...{
      // amount: 100, always not set for setup
      mode: 'setup', // 'payment', 'subscription'
      locale: 'en',
      currency: 'usd',
      // paymentMethodTypes: ['card', 'link', 'klarna', 'affirm'],
      paymentMethodCreation: 'manual', // handled by server
      loader: 'always',
    },
  };

  addressElementOptions: StripeAddressElementOptions = {
    mode: 'billing',
    autocomplete: { mode: 'automatic' },
    fields: { phone: 'always' },
    defaultValues: {
      address: {
        line2: '_', // defaulted so full form shows and errors can be displayed
        country: 'US',
      },
    },
    validation: {
      phone: {
        required: 'always',
      },
    },
  };

  timeoutErrorSubscription!: Subscription;
  waitCount = 0;

  stripe!: StripeInstance;

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    private stripeService: StripeFactoryService,
    private traceService: TraceService,
    private errorService: ErrorService,
    private requestFacade: RequestFacade,
    private shopFacade: ShopFacade,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(MOCK_ENABLED) private isMock: boolean
  ) {
    this.stripe = this.stripeService.create(appConfig.stripe?.pk, {
      stripeAccount: appConfig.stripe?.acct,
    });
    if (this.isMock) {
      this.addressElementOptions.autocomplete = { mode: 'disabled' };
    }
    this.timeoutErrorSubscription = this.requestFacade.clearTimeoutError$
      .pipe(filter((clear) => clear === true))
      .subscribe({
        next: () => {
          this.state$.next(ButtonState.ready);
          this.requestFacade.markTimeoutErrorCleared();
        },
      });
    this.readyToPurchase$ = this.shopFacade.me$.pipe(
      map((me: CustomerProperties | undefined | null) => {
        if (me) {
          return me.integrations &&
            me.integrations.stripe &&
            me.integrations.stripe.id
            ? true
            : false;
        }
        return false;
      })
    );

    const waitForIt = timer(10000, 15000).subscribe({
      next: () => {
        this.readyToPurchase$.pipe(take(1)).subscribe({
          next: (ready) => {
            if (ready) {
              waitForIt.unsubscribe();
            } else {
              switch (this.waitCount) {
                case 0:
                  this.delayMessage$.next(
                    'This is taking a wee bit longer than expected. Please wait while we check your account.'
                  );
                  break;
                case 1:
                  this.delayMessage$.next(
                    'Sorry for the delay. We are still checking your account.'
                  );
                  break;
                case 2:
                  this.delayMessage$.next(
                    'Unfortunately, we were unable to get your information. We will refresh the page and try again. If this problem persists, please contact support.'
                  );
                  break;
                default:
                  setTimeout(() => this.doc.location.reload());
              }
              this.waitCount++;
            }
          },
        });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.timeoutErrorSubscription)
      this.timeoutErrorSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { total, processingState } = changes;
    if (total && total.currentValue > 0) {
      const amount = total.currentValue;
      this.paymentElementOptions = {
        ...this.paymentElementOptions,
        // ...({ amount } as StripeElementsOptionsMode),
        setupFutureUsage: 'off_session',
      };
    }

    if (processingState && processingState.currentValue) {
      this.state$.next(processingState.currentValue);
    }
  }

  onElementsChange(ev: StripePaymentElementChangeEvent) {
    if (this.state$.getValue() === ButtonState.errored) {
      this.retryRequested.emit();
      this.state$.next(ButtonState.ready);
    }
  }

  async createPaymentMethod() {
    if (this.state$.getValue() === ButtonState.ready) {
      // skip stripe if free and no future payment required
      if (this.total === 0 && !this.requiresFuturePaymentMethod) {
        this.state$.next(ButtonState.processing);
        return this.checkoutRequested.emit();
      }

      // no need to submit payment elements since they will be validated on create payment
      // calling verify on payment elements will cause dbl show on google pay which fails due to browser interaction

      const resp = await this.address.elements.submit();
      if (resp.error) {
        const err = new ShopError(
          resp.error.message ?? 'unknown issue',
          'Payment Issue',
          'Missing required fields for payment. ' + resp.error.message
        );
        this.errorService.handleError(err);
        return;
      }

      if (this.stripe) {
        this.state$.next(ButtonState.processing);
        this.traceService.startSpan(SpanTypes.stripePaymentMethod);
        const { paymentMethod, error } = await firstValueFrom(
          this.stripe.createPaymentMethod({
            elements: this.payment.elements,
          })
        );
        if (!error) {
          this.traceService.endSpan(SpanTypes.stripePaymentMethod);
          this.checkoutRequested.emit(paymentMethod);
          return;
        }
        this.state$.next(ButtonState.ready);
        this.traceService.endSpan(
          SpanTypes.stripePaymentMethod,
          new Error(error.message)
        );
        const err = new ShopError(
          error.message ?? 'unknown issue',
          'Order Issue',
          'Unable to process order.'
        );
        this.errorService.handleError(err);
      }
    }
  }
}
