<div [attr.data-testid]="testIdPrefix"
  class="tw-group tw-relative tw-flex tw-flex-col tw-overflow-hidden tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white">
  <div
    class="tw-relative tw-aspect-h-3 tw-aspect-w-4 tw-bg-gray-200 sm:tw-aspect-none group-hover:tw-opacity-75 sm:tw-h-60">
    <sidkik-asset *ngIf="purchasable.data?.product?.data?.image?.id && !purchasable.data?.product?.data?.video?.id"
      [attr.data-testid]="testIdPrefix + '-image'" [asset]="purchasable.data?.product?.data?.image"
      class="sm:!tw-relative tw-h-full tw-w-full tw-object-cover tw-object-center sm:tw-h-full sm:tw-w-full"
      [isResponsive]="true"></sidkik-asset>
    <sidkik-asset *ngIf="purchasable.data?.product?.data?.video?.id" [attr.data-testid]="testIdPrefix + '-video'"
      [asset]="purchasable.data?.product?.data?.video" [thumbOnly]="true"
      class="tw-h-full tw-w-full tw-object-cover tw-object-center sm:tw-h-full sm:tw-w-full"
      customRender="&w=500"></sidkik-asset>
  </div>
  <div class="tw-flex tw-flex-1 tw-flex-col tw-space-y-2 tw-p-4">
    <div class="tw-flex tw-flex-1 tw-flex-row  tw-text-gray-400 tw-text-sm tw-space-x-4 tw-mb-1">
      <div class="tw-flex tw-flex-row tw-items-center tw-space-x-1"
        *ngIf="purchasable.data?.product?.data?.tags?.length ?? 0  > 0">
        <sidkik-icon svgIcon="tags" class="tw-h-4 tw-w-4"></sidkik-icon> <span
          [attr.data-testid]="testIdPrefix + '-tags'" class="tw-values">{{
          purchasable.data?.product?.data?.tags?.join(', ')
          }}</span>
      </div>
      <div class="tw-flex tw-flex-row tw-items-center tw-space-x-1"
        *ngIf="purchasable.data?.product?.data?.metadata?.skillLevel">
        <sidkik-icon svgIcon="skill_level" class="tw-h-4 tw-w-4"></sidkik-icon> <span
          [attr.data-testid]="testIdPrefix + '-skill-level'" class="tw-values">{{
          purchasable.data?.product?.data?.metadata?.skillLevel }}</span>
      </div>
      <div class="tw-flex tw-flex-row tw-items-center tw-space-x-1"
        *ngIf="purchasable.data?.product?.data?.metadata?.timeToComplete">
        <sidkik-icon svgIcon="read_time" class="tw-h-4 tw-w-4"></sidkik-icon> <span class="tw-values"
          [attr.data-testid]="testIdPrefix + '-time-to-complete'">{{
          purchasable.data?.product?.data?.metadata?.timeToComplete }}</span>
      </div>
    </div>
    <h3 class="tw-text-sm tw-font-medium tw-text-gray-900">
      <a [routerLink]="['../', purchasable.data?.product?.id]" [attr.data-testid]="testIdPrefix + '-name'">
        <span aria-hidden="true" class="tw-absolute tw-inset-0"></span>
        {{ purchasable.data?.product?.data?.name}}
      </a>
    </h3>
    <p class="tw-text-sm tw-text-gray-500 tw-line-clamp-3" [attr.data-testid]="testIdPrefix + '-caption'">
      {{
      purchasable.data?.product?.data?.caption}}</p>
    <div class="tw-flex tw-flex-1 tw-flex-row tw-justify-start" [attr.data-testid]="testIdPrefix + '-price'">
      <div *ngFor="let sku of purchasable.data?.sku; let i = index"
        class="tw-text-base tw-font-medium tw-text-gray-900">
        {{sku.data.price
        * .01 |
        currency}}<span class="tw-text-gray-500 tw-font-normal tw-text-sm"
          *ngIf="sku.data?.type === 'recurring'">/{{sku.data.recurring?.period}}</span>
        <span class="tw-mx-2 tw-text-gray-200" *ngIf="i !== ((purchasable.data?.sku?.length ?? 0) - 1)">|</span>
      </div>
    </div>
  </div>
</div>