<div class="tc-editor cdx-block">
  <div class="tc-table__wrap">
    <table class="min-w-full divide-y divide-gray-300">
      <tbody class="divide-y divide-gray-200">
        <tr class="tw-w-full" *ngFor="let r of content?.content; let ri = index;">
          <td class="tc-table__cell" *ngFor="let c of r; let ci = index;">
            <div class="tc-table__area">
              <div class="tc-table__inp" [innerHTML]="content.content[ri][ci]">
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>