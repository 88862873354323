import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromShop from './+state/shop.reducer';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UIModule } from '@sidkik/ui';
import { RoutingModule } from './routing.module';
import { ProductEffects } from './+state/product/product.effects';
import { SKUEffects } from './+state/sku/sku.effects';
import { ProductListComponent } from './components/product/product-list/product-list.component';
import { ProductCardComponent } from './components/product/product-card/product-card.component';
import { ProductFacade } from './+state/product/product.facade';
import { SKUFacade } from './+state/sku/sku.facade';
import { ShopFacade } from './+state/shop.facade';
import { ProductDetailComponent } from './components/product/product-detail/product-detail.component';
import { CartFacade } from './+state/cart/cart.facade';
import { CartEffects } from './+state/cart/cart.effects';
import { CartListComponent } from './components/cart/cart-list/cart-list.component';
import { CartItemComponent } from './components/cart/cart-item/cart-item.component';
import { LetDirective } from '@ngrx/component';
import {
  provideNgxStripe,
  StripeAddressComponent,
  StripeElementsDirective,
  StripePaymentElementComponent,
} from 'ngx-stripe';
import { CheckoutStepsComponent } from './components/checkout/checkout-steps/checkout-steps.component';
import { CheckoutFormComponent } from './components/checkout/checkout-form/checkout-form.component';
import { CheckoutSummaryComponent } from './components/checkout/checkout-summary/checkout-summary.component';
import { RequestFacade } from './+state/request/request.facade';
import { RequestEffects } from './+state/request/request.effects';
import { GlobalModule } from '@sidkik/global';
import { DiscountFormComponent } from './components/checkout/discount-form/discount-form.component';
import { SidkikAPIModule } from '@sidkik/sidkik-api';
import { ProductGuard } from './guards/product.guard';
import { UpsellItemComponent } from './components/cart/upsell-item/upsell-item.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarViewComponent } from './components/calendar/view/calendar-view.component';
import { MapEffects } from './+state/map/map.effects';
import { MapFacade } from './+state/map/map.facade';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromShop.SHOP_FEATURE_KEY, fromShop.shopReducer),
    EffectsModule.forFeature([
      ProductEffects,
      SKUEffects,
      CartEffects,
      RequestEffects,
      MapEffects,
    ]),
    UIModule,
    StripePaymentElementComponent,
    StripeElementsDirective,
    StripeAddressComponent,
    LetDirective,
    GlobalModule,
    SidkikAPIModule,
    FullCalendarModule,
  ],
  declarations: [
    ProductListComponent,
    ProductCardComponent,
    ProductDetailComponent,
    CartListComponent,
    CartItemComponent,
    CheckoutStepsComponent,
    CheckoutFormComponent,
    CheckoutSummaryComponent,
    DiscountFormComponent,
    UpsellItemComponent,
    CalendarViewComponent,
  ],
  providers: [
    provideNgxStripe(),
    ProductFacade,
    SKUFacade,
    ShopFacade,
    CartFacade,
    RequestFacade,
    ProductGuard,
    MapFacade,
  ],
})
export class ShopModule {}
