import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { AssetProperties, Integrations, Meta } from '@sidkik/db';

export interface ImageBlock {
  url: string;
  srcset?: string;
  caption: string;
  withBorder: boolean;
  withBackground: boolean;
  stretched: boolean;
}

@Component({
  selector: 'sidkik-editor-js-image',
  templateUrl: './editor-js-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsImageComponent implements OnInit {
  @Input() content!: ImageBlock;

  isAssetHosted = false;
  asset: AssetProperties | undefined = undefined;

  ngOnInit(): void {
    // determine if the image is sidkik hosted
    if (this.content.srcset?.includes('assets.sidkik.app')) {
      // "https://local-assets.sidkik.app/fir-account-3f2bd/fir-account-3f2bd%2Fassets%2Fcu_855j5grnbvnhpi%2FAmy-Does-Pilates-Testimonials-Naomi.webp?alt=media&token=1023ce63-e153-4072-ae0d-8c5b0710bd1f&gravity=smart&w=250 250w,https://local-assets.sidkik.app/fir-account-3f2bd/fir-account-3f2bd%2Fassets%2Fcu_855j5grnbvnhpi%2FAmy-Does-Pilates-Testimonials-Naomi.webp?alt=media&token=1023ce63-e153-4072-ae0d-8c5b0710bd1f&gravity=smart&w=480 480w,https://local-assets.sidkik.app/fir-account-3f2bd/fir-account-3f2bd%2Fassets%2Fcu_855j5grnbvnhpi%2FAmy-Does-Pilates-Testimonials-Naomi.webp?alt=media&token=1023ce63-e153-4072-ae0d-8c5b0710bd1f&gravity=smart&w=1000 1000w,https://local-assets.sidkik.app/fir-account-3f2bd/fir-account-3f2bd%2Fassets%2Fcu_855j5grnbvnhpi%2FAmy-Does-Pilates-Testimonials-Naomi.webp?alt=media&token=1023ce63-e153-4072-ae0d-8c5b0710bd1f&gravity=smart&w=1800 1800w,https://local-assets.sidkik.app/fir-account-3f2bd/fir-account-3f2bd%2Fassets%2Fcu_855j5grnbvnhpi%2FAmy-Does-Pilates-Testimonials-Naomi.webp?alt=media&token=1023ce63-e153-4072-ae0d-8c5b0710bd1f&gravity=smart&w=2400 2400w"
      // https://local-assets.sidkik.app/fir-account-3f2bd/fir-account-3f2bd%2Fassets%2Fcu_855j5grnbvnhpi%2FAmy-Does-Pilates-Testimonials-Naomi.webp?alt=media&token=1023ce63-e153-4072-ae0d-8c5b0710bd1f&gravity=smart&w=250
      this.isAssetHosted = true;
      // split the srcset by , to get a url
      const urls = this.content.srcset.split(' ');

      // convert to a url and extract the token
      const url = new URL(urls[0]);
      const token = url.searchParams.get('token');
      // get the key from the path which is the content between the last / and ?
      const key = url.pathname.split('/').pop()?.split('?')[0];
      // create an asset object
      this.asset = {
        id: '',
        data: {
          token: token ?? '',
          type: 'image',
          key: key ?? '',
          size: 0,
          uploadedBytes: 0,
          url: this.content.url,
        },
        meta: {} as Meta,
        integrations: {} as Integrations,
      };
    }
  }
}
