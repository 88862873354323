<div class="constrained tw-mt-6"
  *ngrxLet="{platforms: platforms$, membership: membership$, courseAndOrChallenge: courseAndOrChallenge$, session: session$, itemsDisplay: itemsDisplay$, items: items$} as purchasables">
  @switch(true){
  @case (purchasables.itemsDisplay === itemsDisplayTypes.List) {
  <div class="tw-flex tw-flex-col tw-gap-10">
    @for(item of purchasables.items; track item) {
    @switch (item) {
    @case (shopSections.CoursesAndChallenges) {
    @if (purchasables.courseAndOrChallenge && purchasables.courseAndOrChallenge.length > 0) {
    <div>
      <div data-testid="shop-products-cac-title" class="tw-border-b tw-border-gray-200 tw-pb-3 tw-mb-8">
        <h3 class="tw-font-headings tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Courses and Challenges
        </h3>
      </div>
      <div data-testid="shop-products-cac-list"
        class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-x-6 sm:tw-gap-y-10 lg:tw-grid-cols-4 lg:tw-gap-x-8">
        <sidkik-product-card *ngFor="let purchasable of purchasables.courseAndOrChallenge; index as i"
          [purchasable]="purchasable" [testIdPrefix]="'shop-products-cac-' + i"></sidkik-product-card>
      </div>
    </div>
    }
    }
    @case (shopSections.Platforms) {
    @if (purchasables.platforms && purchasables.platforms.length > 0) {
    <div>
      <div data-testid="shop-products-sidkik-title" class="tw-border-b tw-border-gray-200 tw-pb-3 tw-mb-8">
        <h3 class="tw-font-headings tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Sidkik</h3>
      </div>
      <div data-testid="shop-products-sidkik-list"
        class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-x-6 sm:tw-gap-y-10 lg:tw-grid-cols-4 lg:tw-gap-x-8">
        <sidkik-product-card *ngFor="let purchasable of purchasables.platforms"
          [purchasable]="purchasable"></sidkik-product-card>
      </div>
    </div>
    }
    }
    @case (shopSections.Sessions) {
    @if (purchasables.session && purchasables.session.length > 0) {
    <div>
      <div data-testid="shop-products-session-title" class="tw-border-b tw-border-gray-200 tw-pb-3 tw-mb-8">
        <h3 class="tw-font-headings tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Sessions</h3>
      </div>
      <div data-testid="shop-products-session-list"
        class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-x-6 sm:tw-gap-y-10 lg:tw-grid-cols-4 lg:tw-gap-x-8">
        <sidkik-product-card *ngFor="let purchasable of purchasables.session; index as i" [purchasable]="purchasable"
          [testIdPrefix]="'shop-products-session-' + i"></sidkik-product-card>
      </div>
    </div>
    }
    }
    @case (shopSections.Memberships) {
    @if (purchasables.membership && purchasables.membership.length > 0) {
    <div>
      <div data-testid="shop-products-membership-title" class="tw-border-b tw-border-gray-200 tw-pb-3 tw-mb-8">
        <h3 class="tw-font-headings tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Membership</h3>
      </div>
      <div data-testid="shop-products-membership-list"
        class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-x-6 sm:tw-gap-y-10 lg:tw-grid-cols-4 lg:tw-gap-x-8">
        <sidkik-product-card *ngFor="let purchasable of purchasables.membership; index as i" [purchasable]="purchasable"
          [testIdPrefix]="'shop-products-membership-' + i"></sidkik-product-card>
      </div>
    </div>
    }
    }
    }
    }
  </div>
  }
  @case (purchasables.itemsDisplay === itemsDisplayTypes.Tabs) {
  <sidkik-tabs>
    @for(item of purchasables.items; track item) {
    @switch (item) {
    @case (shopSections.CoursesAndChallenges) {
    @if (purchasables.courseAndOrChallenge && purchasables.courseAndOrChallenge.length > 0) {
    <sidkik-tab [title]="'Courses And Challenges'">
      <div data-testid="shop-products-cac-list"
        class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-x-6 sm:tw-gap-y-10 lg:tw-grid-cols-4 lg:tw-gap-x-8">
        <sidkik-product-card *ngFor="let purchasable of purchasables.courseAndOrChallenge; index as i"
          [purchasable]="purchasable" [testIdPrefix]="'shop-products-cac-' + i"></sidkik-product-card>
      </div>
    </sidkik-tab>
    }
    }
    @case (shopSections.Platforms) {
    @if (purchasables.platforms && purchasables.platforms.length > 0) {
    <sidkik-tab [title]="'Sidkik'">
      <div data-testid="shop-products-sidkik-list"
        class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-x-6 sm:tw-gap-y-10 lg:tw-grid-cols-4 lg:tw-gap-x-8">
        <sidkik-product-card *ngFor="let purchasable of purchasables.platforms"
          [purchasable]="purchasable"></sidkik-product-card>
      </div>
    </sidkik-tab>
    }
    }
    @case (shopSections.Sessions) {
    @if (purchasables.session && purchasables.session.length > 0) {
    <sidkik-tab [title]="'Sessions'">
      <div data-testid="shop-products-session-list"
        class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-x-6 sm:tw-gap-y-10 lg:tw-grid-cols-4 lg:tw-gap-x-8">
        <sidkik-product-card *ngFor="let purchasable of purchasables.session; index as i" [purchasable]="purchasable"
          [testIdPrefix]="'shop-products-session-' + i"></sidkik-product-card>
      </div>
    </sidkik-tab>
    }
    }
    @case (shopSections.Memberships) {
    @if (purchasables.membership && purchasables.membership.length > 0) {
    <sidkik-tab [title]="'Memberships'">
      <div data-testid="shop-products-membership-list"
        class="tw-grid tw-grid-cols-1 tw-gap-y-4 sm:tw-grid-cols-2 sm:tw-gap-x-6 sm:tw-gap-y-10 lg:tw-grid-cols-4 lg:tw-gap-x-8">
        <sidkik-product-card *ngFor="let purchasable of purchasables.membership; index as i" [purchasable]="purchasable"
          [testIdPrefix]="'shop-products-membership-' + i"></sidkik-product-card>
      </div>
    </sidkik-tab>
    }
    }
    }
    }
  </sidkik-tabs>
  }
  }
</div>