<div class="sidkik-image-block" *ngIf="content?.url">
  <figure class="sidkik-image tw-flex tw-flex-col tw-items-center tw-justify-center"
    [class.sidkik-image--with-background]="content.withBackground"
    [class.sidkik-image--with-border]="content.withBorder" [class.sidkik-image--stretched]="content.stretched">
    @if(isAssetHosted){
    <sidkik-asset class="!tw-relative tw-object-cover tw-w-full tw-h-full" [asset]="asset"></sidkik-asset>
    } @else {
    <img class="" [src]="content.url" [srcset]="content.srcset" [attr.alt]="content.caption">
    }
    <figcaption *ngIf="content?.caption" class="sidkik-image-caption" [innerHTML]="content.caption">
    </figcaption>
  </figure>
</div>