import { DOCUMENT } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Inject,
} from '@angular/core';

export interface RawHTMLBlock {
  html: string;
}

@Component({
  selector: 'sidkik-editor-js-raw-html',
  templateUrl: './editor-js-raw-html.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsRawHTMLComponent implements OnChanges {
  @Input() content!: RawHTMLBlock;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { content } = changes;
    if (content.currentValue) {
      const scripts = content.currentValue.html.match(
        /<script.*?>([\s\S]*?)<\/script>/g
      );
      scripts?.forEach((script: any) => {
        const scriptElement = this.document.createElement('script');
        // find the src attribute
        const src = script.match(/src="([^"]+)"/);
        if (src) {
          scriptElement.src = src[1];
        }
        // find the type attribute
        const type = script.match(/type="([^"]+)"/);
        if (type) {
          scriptElement.type = type[1];
        }
        // find the async attribute
        const async = script.match(/async="([^"]+)"/);
        if (async) {
          scriptElement.async = async[1];
        }
        // find the defer attribute
        const defer = script.match(/defer="([^"]+)"/);
        if (defer) {
          scriptElement.defer = defer[1];
        }
        // find the charset attribute
        const charset = script.match(/charset="([^"]+)"/);
        if (charset) {
          scriptElement.charset = charset[1];
        }

        // find the inner script
        const innerScript = script.match(/<script.*?>([\s\S]*?)<\/script>/);
        if (innerScript) {
          scriptElement.innerHTML = innerScript[1];
        }
        this.document.body.appendChild(scriptElement);
      });
      // remove the script tags from the html
      content.currentValue.html = content.currentValue.html.replace(
        /<script.*?>([\s\S]*?)<\/script>/g,
        ''
      );
    }
  }
}
