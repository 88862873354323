import { createFeatureSelector, createSelector } from '@ngrx/store';
import { shopStateKey, skuStateKey } from '@sidkik/global';
import { ShopState } from '../shop.reducer';
import { State, selectAll, selectEntities } from './sku.reducer';

export const getShopState = createFeatureSelector<ShopState>(shopStateKey);

export const getState = createSelector(
  getShopState,
  (state: ShopState) => state[skuStateKey]
);

export const getSKULoaded = createSelector(getState, (state: State) => {
  return state?.loaded;
});

export const getSKULoading = createSelector(getState, (state: State) => {
  return state?.loading;
});

export const getSKUError = createSelector(
  getState,
  (state: State) => state.error
);

export const getAllSKUs = createSelector(getState, (state: State) => {
  return selectAll(state);
});

export const getSKUEntities = createSelector(getState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getSKUEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getSKUById = (id: string) =>
  createSelector(getSKUEntities, (entities) => entities[id]);
