import { createAction, props } from '@ngrx/store';

import {
  loadRequestType,
  loadRequestFailureType,
  loadRequestSuccessType,
  noOperation,
  addRequestFailureType,
  addRequestSuccessType,
  addRequestType,
  deleteRequestFailureType,
  deleteRequestSuccessType,
  deleteRequestType,
  MonitorDocumentFailure,
  MonitorDocumentSuccess,
  monitorDocumentFailureType,
  monitorDocumentSuccessType,
  attemptPaymentFailureType,
  attemptPaymentSuccessType,
  attemptPaymentType,
  selectRequestType,
  clearSelectedRequestType,
  clearTimeoutErrorType,
  clearTimeoutErrorCompletedType,
} from '@sidkik/global';
import { OrderRequestProperties } from '@sidkik/db';
import { Update } from '@ngrx/entity';

export const noop = createAction(noOperation);

export const loadRequest = createAction(loadRequestType);

export const loadRequestSuccess = createAction(
  loadRequestSuccessType,
  props<{ requests: OrderRequestProperties[] }>()
);

export const loadRequestFailure = createAction(
  loadRequestFailureType,
  props<{ error: any }>()
);

export const addRequest = createAction(
  addRequestType,
  props<{ request: OrderRequestProperties }>()
);

export const addRequestSuccess = createAction(
  addRequestSuccessType,
  props<{ request: OrderRequestProperties }>()
);

export const addRequestFailure = createAction(
  addRequestFailureType,
  props<{ request: OrderRequestProperties; error: any }>()
);

export const selectRequest = createAction(
  selectRequestType,
  props<{ request: OrderRequestProperties }>()
);

export const clearTimeoutError = createAction(clearTimeoutErrorType);
export const clearTimeoutErrorCompleted = createAction(
  clearTimeoutErrorCompletedType
);

export const clearSelectedRequest = createAction(clearSelectedRequestType);

export const deleteRequest = createAction(
  deleteRequestType,
  props<{ request: OrderRequestProperties }>()
);

export const deleteRequestSuccess = createAction(deleteRequestSuccessType);

export const deleteRequestFailure = createAction(
  deleteRequestFailureType,
  props<{ request: OrderRequestProperties; error: any }>()
);

export const monitorDocumentSuccess = createAction(
  monitorDocumentSuccessType,
  props<MonitorDocumentSuccess>()
);

export const monitorDocumentFailure = createAction(
  monitorDocumentFailureType,
  props<MonitorDocumentFailure>()
);

export const attemptPayment = createAction(
  attemptPaymentType,
  props<{ request: Update<OrderRequestProperties> }>()
);

export const attemptPaymentSuccess = createAction(
  attemptPaymentSuccessType,
  props<{ request: OrderRequestProperties }>()
);

export const attemptPaymentFailure = createAction(
  attemptPaymentFailureType,
  props<{ request: OrderRequestProperties; error: any }>()
);
