/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CartItemProperties,
  PurchasableProperties,
  SKUProperties,
} from '@sidkik/db';

@Component({
  selector: 'sidkik-upsell-item',
  templateUrl: './upsell-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsellItemComponent {
  @Input() item!: Partial<PurchasableProperties> | undefined;
  @Input() testIdPrefix = '';
  @Output() addItem: EventEmitter<{
    item: Partial<PurchasableProperties>;
    sku: SKUProperties;
  }> = new EventEmitter<{
    item: Partial<PurchasableProperties>;
    sku: SKUProperties;
  }>();

  addItemToCart(
    item: Partial<PurchasableProperties> | undefined,
    sku: SKUProperties
  ) {
    if (!item) {
      return;
    }
    this.addItem.emit({ item, sku });
  }

  // removeCartItem(item: Partial<CartItemProperties> | undefined): void {
  //   if (!item) {
  //     return;
  //   }
  //   this.removeItem.emit(item);
  // }
}
