import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

interface NestedListItem {
  content: string;
  items: NestedListItem[];
}
export interface NestedListBlock {
  style: string;
  items: NestedListItem[];
}

@Component({
  selector: 'sidkik-editor-js-nested-list',
  templateUrl: './editor-js-nested-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsNestedListComponent {
  @Input() content!: NestedListBlock;
}
