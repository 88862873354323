import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ProductSelectors from './product.selectors';
import * as ProductActions from './product.actions';
import { Product, ProductProperties, StorageFactory } from '@sidkik/db';
import { take } from 'rxjs';

@Injectable()
export class ProductFacade {
  loaded$ = this.store.pipe(select(ProductSelectors.getProductLoaded));
  loading$ = this.store.pipe(select(ProductSelectors.getProductLoading));
  allProducts$ = this.store.pipe(select(ProductSelectors.getAllProducts));
  selectedProduct$ = this.store.pipe(select(ProductSelectors.getSelected));
  productTags$ = this.store.pipe(select(ProductSelectors.getProductTags));

  constructor(private readonly store: Store) {}

  // addProduct() {
  //   this.store.dispatch(
  //     ProductActions.addProduct({
  //       product: StorageFactory.getFactory(Product).toStorage(),
  //     })
  //   );
  // }

  // updateProduct(product: Product) {
  //   this.store.dispatch(
  //     ProductActions.updateProduct({ product: product.toStorage() })
  //   );
  // }

  // deleteProduct(product: Product) {
  //   const productProperties = product.toStorage ? product.toStorage() : product;
  //   this.store.dispatch(
  //     ProductActions.deleteProduct({
  //       product: productProperties as ProductProperties,
  //     })
  //   );
  // }

  getProduct(id: string) {
    return this.store.pipe(
      select(ProductSelectors.getProductById(id)),
      take(1)
    );
  }
}
