import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProductFacade } from '../+state/product/product.facade';
import { SKUFacade } from '../+state/sku/sku.facade';

@Injectable({
  providedIn: 'root',
})
export class ProductGuard implements CanActivate {
  constructor(
    private productFacade: ProductFacade,
    private skuFacade: SKUFacade
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([
      this.skuFacade.loaded$,
      this.productFacade.loaded$,
    ]).pipe(
      filter(([sl, pl]) => sl && pl),
      map(() => true)
    );
  }
}
