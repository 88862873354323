/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CartItemProperties } from '@sidkik/db';

@Component({
  selector: 'sidkik-cart-item',
  templateUrl: './cart-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemComponent {
  @Input() cartItem!: Partial<CartItemProperties> | undefined;
  @Input() testIdPrefix = '';
  @Output() removeItem: EventEmitter<Partial<CartItemProperties>> =
    new EventEmitter<Partial<CartItemProperties>>();

  removeCartItem(item: Partial<CartItemProperties> | undefined): void {
    if (!item) {
      return;
    }
    this.removeItem.emit(item);
  }

  getTerms(terms: string | undefined): string[] {
    const split = [terms ?? '', ''];
    const sTerms = terms?.split('Terms:');
    if (sTerms?.length == 2) {
      split[0] = sTerms[0];
      split[1] = sTerms[1];
      return split;
    }

    return split;
  }
}
