import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MapId, mapStateKey, shopStateKey } from '@sidkik/global';
import { ShopState } from '../shop.reducer';
import { State, selectAll, selectEntities } from './map.reducer';

export const getShopState = createFeatureSelector<ShopState>(shopStateKey);

export const getState = createSelector(
  getShopState,
  (state: ShopState) => state[mapStateKey]
);

export const getMapLoaded = createSelector(getState, (state: State) => {
  return state?.loaded;
});

export const getMapLoading = createSelector(getState, (state: State) => {
  return state?.loading;
});

export const getMapError = createSelector(
  getState,
  (state: State) => state.error
);

export const getAllMaps = createSelector(getState, (state: State) => {
  return selectAll(state);
});

export const getMapEntities = createSelector(getState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getMapEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getNavMap = createSelector(
  getMapEntities,
  (entities) => entities[MapId.navMap]
);
