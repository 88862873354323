import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch, navigation } from '@ngrx/router-store/data-persistence';
import { SKUProperties, DbService } from '@sidkik/db';
import { EntityType } from '@sidkik/global';
import { map, withLatestFrom } from 'rxjs';
import * as SKUActions from './sku.actions';
import { SKUFacade } from './sku.facade';
import { ProductListComponent } from '../../components/product/product-list/product-list.component';
import { ProductDetailComponent } from '../../components/product/product-detail/product-detail.component';
import { CartListComponent } from '../../components/cart/cart-list/cart-list.component';

@Injectable()
export class SKUEffects {
  /** Navigation Actions */
  navigateToList$ = createEffect(() =>
    this.actions$.pipe(
      withLatestFrom(this.skuFacade.loaded$, this.skuFacade.loading$),
      navigation(ProductListComponent, {
        run: (
          route: ActivatedRouteSnapshot,
          loaded: boolean,
          loading: boolean
        ) => {
          if (!loaded && !loading) {
            return SKUActions.loadSKUs();
          }
          return SKUActions.noop();
        },
        onError(route: ActivatedRouteSnapshot, error: any) {
          return null;
        },
      })
    )
  );

  navigateToDetails$ = createEffect(() =>
    this.actions$.pipe(
      withLatestFrom(this.skuFacade.loaded$, this.skuFacade.loading$),
      navigation(ProductDetailComponent, {
        run: (
          route: ActivatedRouteSnapshot,
          loaded: boolean,
          loading: boolean
        ) => {
          if (!loaded && !loading) {
            return SKUActions.loadSKUs();
          }
          return SKUActions.noop();
        },
        onError(route: ActivatedRouteSnapshot, error: any) {
          return null;
        },
      })
    )
  );

  navigateToShoppingCart$ = createEffect(() =>
    this.actions$.pipe(
      withLatestFrom(this.skuFacade.loaded$, this.skuFacade.loading$),
      navigation(CartListComponent, {
        run: (
          route: ActivatedRouteSnapshot,
          loaded: boolean,
          loading: boolean
        ) => {
          if (!loaded && !loading) {
            return SKUActions.loadSKUs();
          }
          return SKUActions.noop();
        },
        onError(route: ActivatedRouteSnapshot, error: any) {
          return null;
        },
      })
    )
  );

  /** Fetch Actions */
  loadSKUs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SKUActions.loadSKUs),
      fetch({
        id: (a: ReturnType<typeof SKUActions.loadSKUs>) => a.type,
        run: (a: ReturnType<typeof SKUActions.loadSKUs>) => {
          return this.dbService
            .getAllEntities<SKUProperties>(EntityType.SKU)
            .pipe(map((docs) => SKUActions.loadSKUsSuccess({ skus: docs })));
        },
        onError: (_, error) => {
          return null;
        },
      })
    )
  );

  /** Persistance Actions */

  /** General Actions */

  /** Listen for other items */

  constructor(
    private readonly actions$: Actions,
    private readonly skuFacade: SKUFacade,
    private readonly dbService: DbService
  ) {}
}
