import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface WarningBlock {
  title: string;
  message: string;
}

@Component({
  selector: 'sidkik-editor-js-warning',
  templateUrl: './editor-js-warning.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsWarningComponent {
  @Input() content!: WarningBlock;
}
