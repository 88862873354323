import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as MapSelectors from './map.selectors';
import { NavigationMapProperties } from '@sidkik/db';
import { Observable } from 'rxjs';

@Injectable()
export class MapFacade {
  loaded$ = this.store.pipe(select(MapSelectors.getMapLoaded));
  loading$ = this.store.pipe(select(MapSelectors.getMapLoading));
  navMap$ = this.store.pipe(
    select(MapSelectors.getNavMap)
  ) as Observable<NavigationMapProperties>;

  constructor(private readonly store: Store) {}
}
