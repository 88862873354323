<form class="form" [formGroup]="form" name="form">
  <div class="form-body">
    <div class="form-section">
      <div class="form-section-content">
        <div class="form-group sm:tw-col-span-5">
          <label for="code" class="input-label">Discount code</label>
          <div class="tw-mt-1">
            <input data-testid="code-input" type="code" placeholder="" formControlName="code" name="code" id="code"
              class="input" required>

          </div>
        </div>
        <div class="form-group sm:tw-col-span-1 tw-flex tw-items-end">
          <button data-testid="code-apply" (click)="checkCode()" class="btn-sub-primary">Apply</button>
        </div>
        <div class="form-group sm:tw-col-span-6">
          <sidkik-input-error data-testid="code-error" [touched]="form.controls.code.touched"
            [dirty]="form.controls.code.dirty" [errors]="form.controls.code.errors">
          </sidkik-input-error>
        </div>
      </div>
    </div>
  </div>
</form>