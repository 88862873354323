@if (showSimpleSelector$ | async) {
@if ((currentView$ | async) !== views.multiMonthFourMonth) {
<div class="tw-flex tw-items-center tw-mb-4">
  <button (click)="goBackToView(views.multiMonthFourMonth)" class="tw-mr-2 tw-p-2 tw-bg-gray-200 tw-rounded-full">
    <svg class="tw-w-6 tw-h-6 tw-text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
    </svg>
  </button>
  <span>Back</span>
</div>
}
<full-calendar #calendar *ngIf='calendarVisible()' [events]="events" [options]='calendarOptions()'>
  <ng-template #eventContent let-arg>
    <div class="tw-group tw-flex tw-w-full tw-cursor-pointer">
      <div class="tw-ml-3 tw-flex-auto tw-truncate tw-font-medium tw-text-gray-900 group-hover:tw-text-primary-600">
        with {{
        arg.event.extendedProps.userName }}</div>
    </div>
  </ng-template>

  <ng-template #moreLinkContent let-arg>
    <div (click)="moreLinkClick($event)" class="tw-flex tw-justify-center tw-items-center tw-h-full">
      <div class="tw-w-2 tw-h-2 xs:tw-w-3 xs:tw-h-3 tw-bg-primary-300 tw-rounded-full"></div>
    </div>
  </ng-template>

</full-calendar>
} @else {
@if ((currentView$ | async) !== views.multiMonthFourMonth) {
<div class="tw-flex tw-items-center tw-mb-4">
  <button (click)="goBackToView(views.multiMonthFourMonth)" class="tw-mr-2 tw-p-2 tw-bg-gray-200 tw-rounded-full">
    <svg class="tw-w-6 tw-h-6 tw-text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
    </svg>
  </button>
  <span>Back</span>
</div>
}
<full-calendar #calendar *ngIf='calendarVisible()' [events]="events" [options]='calendarOptions()'>
  <ng-template #eventContent let-arg>
    <div class="tw-group tw-flex tw-w-full tw-cursor-pointer">
      <div class="tw-hidden tw-flex-none tw-text-gray-500 group-hover:tw-text-primary-600 sm:tw-block">{{
        arg.timeText }}</div>
      <div class="tw-ml-3 tw-flex-auto tw-truncate tw-font-medium tw-text-gray-900 group-hover:tw-text-primary-600">
        with {{
        arg.event.extendedProps.userName }}</div>
    </div>
  </ng-template>
</full-calendar>
}