import { createFeatureSelector, createSelector } from '@ngrx/store';
import { shopStateKey, requestStateKey } from '@sidkik/global';
import { ShopState } from '../shop.reducer';
import { State, selectAll, selectEntities } from './request.reducer';

export const getShopState = createFeatureSelector<ShopState>(shopStateKey);

export const getState = createSelector(
  getShopState,
  (state: ShopState) => state[requestStateKey]
);

export const getRequestLoaded = createSelector(getState, (state: State) => {
  return state?.loaded;
});

export const getRequestLoading = createSelector(getState, (state: State) => {
  return state?.loading;
});

export const getRequestError = createSelector(
  getState,
  (state: State) => state.error
);

export const getAllRequests = createSelector(getState, (state: State) => {
  return selectAll(state);
});

export const getRequestEntities = createSelector(getState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getRequestEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getClearTimeoutError = createSelector(
  getState,
  (state: State) => state.clearTimeoutError
);

export const getRequestById = (id: string) =>
  createSelector(getRequestEntities, (entities) => entities[id]);
