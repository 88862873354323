import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface ChecklistBlock {
  items: {
    text: string;
    checked: boolean;
  }[];
}

@Component({
  selector: 'sidkik-editor-js-checklist',
  templateUrl: './editor-js-checklist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsChecklistComponent {
  @Input() content!: ChecklistBlock;
}
