<!-- <section class="tw-mx-auto tw-mt-16 tw-flow-root tw-max-w-2xl sm:tw-mt-20 lg:tw-mx-0 lg:tw-max-w-none">
  <div class="tw-relative tw-mx-auto tw-max-w-2xl tw-py-4 sm:tw-py-8 lg:tw-max-w-4xl">
    <div class="-tw-mt-8 sm:-tw-mx-4 sm:tw-columns-2 sm:tw-text-[0] lg:tw-columns-3">
      <div class="tw-pt-8 sm:tw-inline-block sm:tw-w-full sm:tw-px-4"> -->
<figure class="tw-my-4 tw-rounded-2xl tw-bg-white tw-p-8 tw-text-sm tw-leading-6">
  <div class="tw-relative">
    <blockquote class=" tw-text-gray-900">
      <p [innerHTML]="content.text"></p>
    </blockquote>
  </div>
  <figcaption class="tw-mt-6 tw-flex tw-items-center tw-gap-x-4">
    <div class="tw-font-semibold tw-text-gray-900">{{content.caption}}</div>
  </figcaption>
</figure>
<!-- </div>
    </div>
  </div>
</section> -->