<div
  *ngrxLet="{items: cartItems$, coupon: coupon$, promoCode:promoCode$, me: me$, total: total$, subTotal: subTotal$, discounts: discounts$, processingState: processingState$} as cart">
  <div *ngIf="cart.items.length <= 0" class="tw-text-center" data-testid="cart-empty">
    <svg class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
      stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
    </svg>
    <h3 class="tw-mt-2 tw-text-sm tw-font-semibold tw-text-gray-900">No items in your cart yet.</h3>
    <p class="tw-mt-1 tw-text-sm tw-text-gray-500">Get started by picking a course or challenge for purchase.</p>
    <div class="tw-mt-6">
      <a [routerLink]="['/shop']" class="tw-font-medium tw-text-primary-600 hover:tw-text-primary-500">
        Continue Shopping
        <span aria-hidden="true"> &rarr;</span>
      </a>
    </div>
  </div>
  <div *ngIf="cart.items.length > 0"
    class="tw-relative tw-mx-auto tw-grid tw-max-w-7xl tw-grid-cols-1 tw-gap-x-16 lg:tw-grid-cols-2 lg:tw-px-8 lg:tw-pt-16">
    <section data-testid="cart-summary" aria-labelledby="summary-heading"
      class="md:tw-px-10 lg:tw-col-start-2 lg:tw-row-start-1 lg:tw-mx-auto lg:tw-w-full lg:tw-max-w-lg lg:tw-bg-transparent lg:tw-px-0 lg:tw-pb-24 lg:tw-pt-0">
      <sidkik-checkout-summary (removeItem)="removeCartItem($event)" [cartItems]="cart.items"
        [promoCode]="cart.promoCode" [coupon]="cart.coupon" [total]="cart.total" [subTotal]="cart.subTotal"
        [discounts]="cart.discounts"></sidkik-checkout-summary>
    </section>
    <section data-testid="cart-payment" aria-labelledby="payment-and-shipping-heading"
      class="tw-py-16 lg:tw-col-start-1 lg:tw-row-start-1 lg:tw-mx-auto lg:tw-w-full lg:tw-max-w-lg lg:tw-pb-24 lg:tw-pt-0">
      <sidkik-checkout-form [processingState]="cart.processingState" [total]="cart.total"
        (checkoutRequested)="handleOrderRequest($event)"
        [requiresFuturePaymentMethod]="requiresFuturePaymentMethod$ | async" (retryRequested)="clearIssue()"
        [terms]="terms$ | async" [termsRequired]="termsRequired$ | async"></sidkik-checkout-form>
      <div class="tw-rounded-md tw-bg-error-50 tw-p-4 tw-mt-5" *ngIf="orderIssue$ | async as orderIssue">
        <div class="tw-flex">
          <div class="tw-flex-shrink-0">
            <svg class="tw-h-5 tw-w-5 tw-text-error-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="tw-ml-3">
            <h3 class="tw-text-sm tw-font-medium tw-text-error-800">There was an error with your submission</h3>
            <div class="tw-mt-2 tw-text-sm tw-text-error-700">
              <ul role="list" class="tw-list-disc tw-space-y-1 tw-pl-5">
                <li>{{orderIssue}}</li>
              </ul>
            </div>
            <!-- <p class="tw-mt-2 tw-text-xs tw-text-error-700">You can correct and try again</p> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</div>