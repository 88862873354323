import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface ParagraphBlock {
  text: string;
}

@Component({
  selector: 'sidkik-editor-js-paragraph',
  templateUrl: './editor-js-paragraph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsParagraphComponent {
  @Input() content!: ParagraphBlock;
  @Input() textVariant!: string;
  @Input() textAlignment!: string;
}
