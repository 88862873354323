import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as CartSelectors from './cart.selectors';
import * as CartActions from './cart.actions';
import {
  CartItem,
  CartItemProperties,
  CartProperties,
  CouponProperties,
  PromoCodeProperties,
  StorageFactory,
} from '@sidkik/db';
import { lastValueFrom, take, tap } from 'rxjs';
import { EventsService, HTTPTraceHeader } from '@sidkik/global';
import { ShopService } from '@sidkik/sidkik-api';

@Injectable()
export class CartFacade {
  loaded$ = this.store.pipe(select(CartSelectors.getCartLoaded));
  loading$ = this.store.pipe(select(CartSelectors.getCartLoading));
  allCartItems$ = this.store.pipe(select(CartSelectors.getAllCartItems));
  allCartItemsWithDiscounts$ = this.store.pipe(
    select(CartSelectors.getAllCartItemsWithDiscounts)
  );
  coupon$ = this.store.pipe(select(CartSelectors.getCoupon));
  promoCode$ = this.store.pipe(select(CartSelectors.getPromoCode));
  total$ = this.store.pipe(select(CartSelectors.getCartTotal));
  subTotal$ = this.store.pipe(select(CartSelectors.getCartSubTotal));
  discounts$ = this.store.pipe(select(CartSelectors.getCartDiscounts));
  requiresFuturePaymentMethod$ = this.store.pipe(
    select(CartSelectors.getFuturePaymentMethodRequired)
  );
  termsRequired$ = this.store.pipe(select(CartSelectors.getTermsRequired));
  terms$ = this.store.pipe(select(CartSelectors.getTerms));

  constructor(
    private readonly store: Store,
    private eventsService: EventsService,
    private shopAPI: ShopService
  ) {}

  loadCart() {
    this.store.dispatch(CartActions.loadCart());
  }

  addCartItem(item: Partial<CartItemProperties>) {
    this.allCartItems$.pipe(take(1)).subscribe((data) => {
      if (data.find((ci) => ci.id === item.id)) {
        return;
      }
      this.eventsService.addToCart(item as CartItemProperties);
      this.store.dispatch(
        CartActions.addCartItem({
          item: StorageFactory.getFactory(CartItem, {
            id: item.data?.sku?.id,
            data: item.data,
          }).toStorage(),
        })
      );
    });
  }

  checkDiscountCode(
    code: string,
    cart: CartProperties,
    trace?: HTTPTraceHeader
  ): Promise<{
    valid: boolean;
    code?: PromoCodeProperties;
    coupon?: CouponProperties;
    discountedCart?: CartProperties;
    issue?: string;
  }> {
    return lastValueFrom(
      this.shopAPI.checkCoupon(code, cart, trace).pipe(
        tap((data) => {
          if (data.valid) {
            return this.store.dispatch(
              CartActions.checkCouponSuccess({
                valid: data.valid,
                code: data.code,
                coupon: data.coupon,
                discountedCart: data.discountedCart,
                requiresFuturePaymentMethod:
                  data.requiresFuturePaymentMethod ?? false,
              })
            );
          }
          return this.store.dispatch(
            CartActions.checkCouponFailure({
              error: data.issue,
            })
          );
        })
      )
    );
  }

  deleteCartItem(item: CartItemProperties) {
    this.eventsService.removeFromCart(item as CartItemProperties);
    this.store.dispatch(
      CartActions.deleteCartItem({
        item,
      })
    );
  }

  checkTerms() {
    this.store.dispatch(CartActions.checkTerms());
  }

  cleanupCart(items: CartItemProperties[]) {
    items.forEach((item) =>
      this.store.dispatch(
        CartActions.deleteCartItem({
          item,
        })
      )
    );
    this.store.dispatch(CartActions.clearCoupon());
  }
}
