import { Component, ChangeDetectionStrategy } from '@angular/core';

export interface Delimeter {
  data: any;
}

@Component({
  selector: 'sidkik-editor-js-delimiter',
  templateUrl: './editor-js-delimiter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsDelimiterComponent {}
