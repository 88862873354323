import { createAction, props } from '@ngrx/store';

import {
  loadSKUsType,
  loadSKUsFailureType,
  loadSKUsSuccessType,
  noOperation,
  addSKUFailureType,
  addSKUSuccessType,
  addSKUType,
  updateSKUFailureType,
  updateSKUSuccessType,
  updateSKUType,
  deleteSKUFailureType,
  deleteSKUSuccessType,
  deleteSKUType,
  loadSKUsAndSelectSKUSuccessType,
  loadSKUsAndSelectSKUType,
  selectSKUType,
  loadSKUsAndSelectSKUFailureType,
  updateSKUIntegrationsFailureType,
  updateSKUIntegrationsSuccessType,
  updateSKUIntegrationsType,
  MonitorDocumentFailure,
  monitorDocumentFailureType,
  MonitorDocumentSuccess,
  monitorDocumentSuccessType,
} from '@sidkik/global';
import { SKUProperties } from '@sidkik/db';
import { Update } from '@ngrx/entity';

export const noop = createAction(noOperation);

export const loadSKUs = createAction(loadSKUsType);

export const loadSKUsSuccess = createAction(
  loadSKUsSuccessType,
  props<{ skus: SKUProperties[] }>()
);

export const loadSKUsFailure = createAction(
  loadSKUsFailureType,
  props<{ error: any }>()
);

export const loadSKUsAndSelectSKU = createAction(
  loadSKUsAndSelectSKUType,
  props<{ id: string }>()
);

export const loadSKUsAndSelectSKUSuccess = createAction(
  loadSKUsAndSelectSKUSuccessType,
  props<{ products: SKUProperties[]; id: string }>()
);

export const loadSKUsAndSelectSKUFailure = createAction(
  loadSKUsAndSelectSKUFailureType,
  props<{ error: any; id: string }>()
);

export const selectSKU = createAction(selectSKUType, props<{ id: string }>());

export const addSKU = createAction(addSKUType, props<{ sku: SKUProperties }>());

export const addSKUSuccess = createAction(
  addSKUSuccessType,
  props<{ sku: SKUProperties }>()
);

export const addSKUFailure = createAction(
  addSKUFailureType,
  props<{ sku: SKUProperties }>()
);

export const updateSKU = createAction(
  updateSKUType,
  props<{ sku: SKUProperties }>()
);

export const updateSKUSuccess = createAction(
  updateSKUSuccessType,
  props<{ sku: SKUProperties }>()
);

export const updateSKUFailure = createAction(
  updateSKUFailureType,
  props<{ sku: SKUProperties; error: any }>()
);

export const updateSKUIntegrations = createAction(
  updateSKUIntegrationsType,
  props<{ sku: Update<SKUProperties> }>()
);

export const updateSKUIntegrationsSuccess = createAction(
  updateSKUIntegrationsSuccessType,
  props<{ update: Update<SKUProperties>; sku: SKUProperties }>()
);

export const updateSKUIntegrationsFailure = createAction(
  updateSKUIntegrationsFailureType,
  props<{
    update: Update<SKUProperties>;
    error: any;
  }>()
);

export const deleteSKU = createAction(
  deleteSKUType,
  props<{ sku: SKUProperties }>()
);

export const deleteSKUSuccess = createAction(deleteSKUSuccessType);

export const deleteSKUFailure = createAction(
  deleteSKUFailureType,
  props<{ sku: SKUProperties; error: any }>()
);

export const monitorDocumentSuccess = createAction(
  monitorDocumentSuccessType,
  props<MonitorDocumentSuccess>()
);

export const monitorDocumentFailure = createAction(
  monitorDocumentFailureType,
  props<MonitorDocumentFailure>()
);
