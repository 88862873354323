import { createAction, props } from '@ngrx/store';

import {
  loadCartType,
  loadCartFailureType,
  loadCartSuccessType,
  noOperation,
  addCartItemFailureType,
  addCartItemSuccessType,
  addCartItemType,
  deleteCartItemFailureType,
  deleteCartItemSuccessType,
  deleteCartItemType,
  addCouponFailureType,
  clearCouponType,
  checkCouponType,
  checkCouponSuccessType,
  checkTermsType,
  updateTermsType,
  removeTermsType,
} from '@sidkik/global';
import {
  CartItemProperties,
  CartProperties,
  CouponProperties,
  PromoCodeProperties,
} from '@sidkik/db';

export const noop = createAction(noOperation);

export const loadCart = createAction(loadCartType);

export const loadCartSuccess = createAction(
  loadCartSuccessType,
  props<{ items: CartItemProperties[] }>()
);

export const loadCartFailure = createAction(
  loadCartFailureType,
  props<{ error: any }>()
);

export const addCartItem = createAction(
  addCartItemType,
  props<{ item: CartItemProperties }>()
);

export const addCartItemSuccess = createAction(
  addCartItemSuccessType,
  props<{ item: CartItemProperties }>()
);

export const addCartItemFailure = createAction(
  addCartItemFailureType,
  props<{ item: CartItemProperties; error: any }>()
);

export const deleteCartItem = createAction(
  deleteCartItemType,
  props<{ item: CartItemProperties }>()
);

export const deleteCartItemSuccess = createAction(deleteCartItemSuccessType);

export const deleteCartItemFailure = createAction(
  deleteCartItemFailureType,
  props<{ item: CartItemProperties; error: any }>()
);

export const clearCoupon = createAction(clearCouponType);

export const checkCoupon = createAction(
  checkCouponType,
  props<{ code: string }>()
);
export const checkCouponSuccess = createAction(
  checkCouponSuccessType,
  props<{
    valid: boolean;
    code?: PromoCodeProperties;
    coupon?: CouponProperties;
    discountedCart?: CartProperties;
    requiresFuturePaymentMethod?: boolean;
    terms?: string;
    termsRequired?: boolean;
  }>()
);
export const checkCouponFailure = createAction(
  addCouponFailureType,
  props<{ error: any }>()
);

export const checkTerms = createAction(checkTermsType);
export const updateTerms = createAction(
  updateTermsType,
  props<{ terms: string; termsRequired: boolean }>()
);
export const removeTerms = createAction(removeTermsType);
