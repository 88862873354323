import { createFeatureSelector, createSelector } from '@ngrx/store';
import { shopStateKey, cartStateKey } from '@sidkik/global';
import { ShopState } from '../shop.reducer';
import { State, selectAll, selectEntities } from './cart.reducer';
import {
  CartItemProperties,
  CartProperties,
  CouponProperties,
  deepCopy,
} from '@sidkik/db';
import { get } from 'http';

// update cart with coupon discount items instead of calculating it in the component
// if new items are added to the cart, verify the coupon again
// if items are removed from the cart, verify the coupon again

export const getShopState = createFeatureSelector<ShopState>(shopStateKey);

export const getState = createSelector(
  getShopState,
  (state: ShopState) => state[cartStateKey]
);

export const getCartLoaded = createSelector(getState, (state: State) => {
  return state?.loaded;
});

export const getCartLoading = createSelector(getState, (state: State) => {
  return state?.loading;
});

export const getCartError = createSelector(
  getState,
  (state: State) => state.error
);

export const getCoupon = createSelector(
  getState,
  (state: State) => state.coupon
);

export const getDiscountedCart = createSelector(
  getState,
  (state: State) => state.discountedCart
);

export const getPromoCode = createSelector(
  getState,
  (state: State) => state.promoCode
);

export const getCartItems = createSelector(getState, (state: State) =>
  selectAll(state)
);

export const getAllCartItems = createSelector(
  getCartItems,
  (items: CartItemProperties[]) => items
);

export const getAllCartItemsWithDiscounts = createSelector(
  getCartItems,
  getDiscountedCart,
  (items: CartItemProperties[], discountedCart?: CartProperties) => {
    if (!discountedCart || discountedCart.data.items.length === 0) {
      const copyOf = deepCopy(items) as CartItemProperties[];
      // remove the discount amount from the items
      copyOf.forEach((item) => {
        item.data.discountAmount = 0;
        item.data.discountApplication = '';
      });
      return copyOf;
    }

    // merge the discounted items with the cart items
    const discountedItems = discountedCart.data.items;
    const copyOf = deepCopy(items) as CartItemProperties[];
    discountedItems.forEach((discountedItem) => {
      const existingItem = copyOf.find(
        (existing) => existing.id === discountedItem.id
      );
      if (existingItem) {
        existingItem.data.discountAmount =
          discountedItem.data.discountAmount ?? 0;
        existingItem.data.discountApplication =
          discountedItem.data.discountApplication ?? '';
      }
    });

    return copyOf;
  }
);

export const getCartSubTotal = createSelector(
  getAllCartItems,
  (items: CartItemProperties[]) =>
    (items ?? []).reduce((acc, cur) => acc + cur.data.sku.data.price, 0)
);

export const getCartDiscounts = createSelector(
  getAllCartItemsWithDiscounts,
  (items: CartItemProperties[]) =>
    (items ?? []).reduce((acc, cur) => acc + (cur.data.discountAmount ?? 0), 0)
);

export const getDiscountRequiresFuturePaymentMethod = createSelector(
  getState,
  (state: State) => state.discountRequiresFuturePaymentMethod ?? false
);

export const getTerms = createSelector(getState, (state: State) => state.terms);
export const getTermsRequired = createSelector(
  getState,
  (state: State) => state.termsRequired ?? false
);

export const getCartTotal = createSelector(
  getCartSubTotal,
  getCartDiscounts,
  (subTotal: number, discounts: number) => {
    const total = (subTotal ?? 0) - (discounts ?? 0);
    return total <= 0 ? 0 : total;
  }
);

export const getFuturePaymentMethodRequired = createSelector(
  getDiscountRequiresFuturePaymentMethod,
  getCoupon,
  getCartTotal,
  (
    discountRequiresFuturePaymentMethod: boolean,
    coupon: CouponProperties | undefined,
    cartTotal: number
  ) => {
    // coupon check runs server side and validates the need for future payment method
    // if discount requires future payment method go ahead and return true
    if (discountRequiresFuturePaymentMethod) {
      logger.info(
        'shop:state:cart:selectors:getFuturePaymentMethodRequired',
        'discountRequiresFuturePaymentMethod is true - future payment method required'
      );
      return true;
    }

    // coupon was checked and there is no requirement for future payment method
    if (coupon && !discountRequiresFuturePaymentMethod) {
      logger.info(
        'shop:state:cart:selectors:getFuturePaymentMethodRequired',
        'coupon was checked and there is no requirement for future payment method'
      );
      return false;
    }

    // if the total is greater than 0, then it will require a future payment method (either executed immediately or in the future with a subscription/deferred session)
    if (cartTotal > 0) {
      logger.info(
        'shop:state:cart:selectors:getFuturePaymentMethodRequired',
        'cartTotal is greater than 0 - future payment method required'
      );
      return true;
    }

    logger.info(
      'shop:state:cart:selectors:getFuturePaymentMethodRequired',
      'future payment method not required'
    );
    return false;
  }
);

export const getCartItemEntities = createSelector(getState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getCartItemEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getCartItemById = (id: string) =>
  createSelector(getCartItemEntities, (entities) => entities[id]);
