<div *ngIf="selectedPurchasable$ | async as selectedPurchasable" class="tw-bg-white constrained tw-mt-6">
  <!-- Product -->
  <div class="lg:tw-grid lg:tw-grid-cols-7 lg:tw-grid-rows-1 lg:tw-gap-x-8 lg:tw-gap-y-10 xl:tw-gap-x-16">
    <!-- Product image -->
    <div class="lg:tw-col-span-4 lg:tw-row-end-1">
      <div
        *ngIf="selectedPurchasable.data?.product?.data?.image?.id && !selectedPurchasable.data?.product?.data?.video?.id"
        class="tw-aspect-h-3 tw-aspect-w-4 tw-overflow-hidden tw-rounded-lg tw-bg-gray-100">
        <sidkik-asset data-testid="product-detail-image" class="tw-object-cover tw-object-center tw-w-full tw-h-full"
          [asset]="selectedPurchasable.data?.product?.data?.image" [isResponsive]="true"></sidkik-asset>
      </div>
      <div *ngIf="selectedPurchasable.data?.product?.data?.video?.id"
        class="tw-aspect-h-9 tw-aspect-w-16 tw-overflow-hidden tw-rounded-lg tw-bg-gray-100">
        <sidkik-asset data-testid="product-detail-video" class="tw-object-cover tw-object-center tw-w-full tw-h-full"
          [asset]="selectedPurchasable.data?.product?.data?.video"></sidkik-asset>
      </div>
    </div>
    <!-- Product details -->
    <div
      class="tw-mx-auto tw-w-full tw-mt-14 tw-max-w-2xl sm:tw-mt-16 lg:tw-col-span-3 lg:tw-row-span-2 lg:tw-row-end-2 lg:tw-mt-0 lg:tw-max-w-none">
      <div class="tw-flex tw-flex-col-reverse">
        <div class="tw-mt-4">
          <h1 data-testid="product-detail-name"
            class="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-3xl">
            {{selectedPurchasable.data?.product?.data?.name}}</h1>

          <!-- <h2 id="information-heading" class="tw-sr-only">Product information</h2>
            <p class="tw-mt-2 tw-text-sm tw-text-gray-500">Version 1.0 (Updated <time datetime="2021-06-05">June 5, 2021</time>)
            </p> -->
        </div>
      </div>

      <p data-testid="product-detail-caption" class="tw-mt-6 tw-text-gray-500">
        {{selectedPurchasable.data?.product?.data?.caption}}</p>
      <div class="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-4 sm:tw-grid-cols-2">
        @if (buyEnabled$ | async) {
        <button [attr.data-testid]="'product-detail-pay-button-' + i"
          *ngFor="let sku of selectedPurchasable?.data?.sku; index as i" type="button" class="btn-primary"
          (click)="addItemToCart(selectedPurchasable, sku)">Pay
          {{sku.data.price * 0.01| currency}}<span class="tw-font-normal tw-text-xs"
            *ngIf="sku.data?.type === 'recurring'">/{{sku.data.recurring?.period}}</span></button>
        } @else {
        <p class="tw-text-gray-500 tw-text-xs tw-w-full tw-col-span-2">This product is not available for purchase at
          this time</p>
        }
      </div>

      @if (selectedPurchasable.data?.product?.data?.tags?.length ?? 0 > 0 ||
      selectedPurchasable.data?.product?.data?.metadata?.skillLevel ||
      selectedPurchasable.data?.product?.data?.metadata?.timeToComplete) {
      <div class="tw-mt-10 tw-border-t tw-border-gray-200 tw-pt-10">
        <h3 class="tw-text-sm tw-font-medium tw-text-gray-900">Details</h3>
        <div class="tw-prose tw-prose-sm tw-mt-4 tw-text-gray-500">
          <div class="tw-flex tw-flex-row tw-items-center tw-space-x-3 tw-mb-3"
            *ngIf="(selectedPurchasable.data?.product?.data?.tags?.length ?? 0) > 0"><sidkik-icon svgIcon="tags"
              class="tw-h-6 tw-w-6"></sidkik-icon>
            <div>{{
              selectedPurchasable.data?.product?.data?.tags?.join(', ') }}</div>
          </div>

          <div class="tw-flex tw-flex-row tw-items-center tw-space-x-3 tw-mb-3"
            *ngIf="selectedPurchasable.data?.product?.data?.metadata?.skillLevel"><sidkik-icon svgIcon="skill_level"
              class="tw-h-6 tw-w-6"></sidkik-icon>
            <div>{{
              selectedPurchasable.data?.product?.data?.metadata?.skillLevel }}</div>
          </div>

          <div class="tw-flex tw-flex-row tw-items-center tw-space-x-3 tw-mb-3"
            *ngIf="selectedPurchasable.data?.product?.data?.metadata?.timeToComplete"> <sidkik-icon svgIcon="read_time"
              class="tw-h-6 tw-w-6"></sidkik-icon>
            <div>{{
              selectedPurchasable.data?.product?.data?.metadata?.timeToComplete }}
            </div>
          </div>
        </div>
      </div>
      }


      @if(showAvailability(selectedPurchasable)){
      @if (events$ | async; as events && events.length > 0) {
      <div class="tw-mt-10 tw-border-t tw-border-gray-200 tw-pt-10">
        <h3 class="tw-text-sm tw-font-medium tw-text-gray-900">Availability</h3>
        @if (selectedEvent$ | async; as selectedEvent){
        <div class="tw-relative tw-flex tw-space-x-6 tw-py-6">
          @if (selectedEvent?.extendedProps?.['userAvatar'] && selectedEvent?.extendedProps?.['userAvatar'] !== '')
          {
          <img class="tw-h-14 tw-w-14 tw-rounded-full tw-object-cover"
            [src]="selectedEvent?.extendedProps?.['userAvatar']" alt="">
          } @else {
          <div class="tw-h-14 tw-w-14 tw-rounded-full tw-bg-gray-100 tw-overflow-hidden">
            <svg fill="currentColor" viewBox="0 0 24 24" class="tw-h-full tw-w-full tw-text-gray-300">
              <path
                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z">
              </path>
            </svg>
          </div>
          }
          <div class="tw-flex-auto">
            <h3 class="tw-pr-10 tw-font-semibold tw-text-gray-900 xl:tw-pr-0">
              {{selectedEvent?.extendedProps?.['userName'] ?? selectedEvent?.extendedProps?.['userId']}}
            </h3>
            <dl class="tw-mt-2 tw-flex tw-flex-col tw-text-gray-500 xl:tw-flex-row">
              <div class="tw-flex tw-items-start tw-space-x-3">
                <dt class="tw-mt-0.5">
                  <span class="tw-sr-only">Date</span>
                  <svg class="tw-h-5 tw-w-5 tw-text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                      clip-rule="evenodd" />
                  </svg>
                </dt>
                <dd><time datetime="2022-01-10T17:00">{{
                    selectedEvent?.extendedProps?.['availability']?.['startTimeUnix'] * 1000 | date: "MMM d, y 'at'
                    h:mma" }}</time>
                </dd>
              </div>
              <!-- <div
                class="tw-mt-2 tw-flex tw-items-start tw-space-x-3 xl:tw-ml-3.5 xl:tw-mt-0 xl:tw-border-l xl:tw-border-gray-400 xl:tw-border-opacity-50 xl:tw-pl-3.5">
                <dt class="tw-mt-0.5">
                  <span class="tw-sr-only">Location</span>
                  <svg class="tw-h-5 tw-w-5 tw-text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                      clip-rule="evenodd" />
                  </svg>
                </dt>
                <dd>Starbucks</dd>
              </div> -->
            </dl>
          </div>

          <div class="tw-absolute tw-right-0 tw-top-0 tw-hidden tw-pt-4 sm:tw-block">
            <button type="button" (click)="clearSelectedEvent()"
              class="tw-rounded-md tw-bg-white tw-text-gray-400 tw-hover:text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:ring-offset-2">
              <span class="tw-sr-only">Close</span>
              <svg class="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        }
        <div class="tw-mt-4 tw-text-gray-500">
          <sidkik-shop-calendar-view [events]="events$ | async" (eventSelected)="selectEvent($event)">
          </sidkik-shop-calendar-view>
        </div>
      </div>
      } @else {
      <div class="tw-mt-10 tw-border-t tw-border-gray-200 tw-pt-10">
        <h3 class="tw-text-sm tw-font-medium tw-text-gray-900">No Availability</h3>
      </div>
      }
      }

      <div class="tw-mt-10 tw-border-t tw-border-gray-200 tw-pt-10"
        *ngIf="selectedPurchasable.data?.product?.data?.license">
        <h3 class="tw-text-sm tw-font-medium tw-text-gray-900">License</h3>
        <p class="tw-mt-4 tw-text-sm tw-text-gray-500 tw-whitespace-pre-wrap" data-testid="product-detail-license">{{
          selectedPurchasable.data?.product?.data?.license }}
        </p>
      </div>

      <div class="tw-mt-10 tw-border-t tw-border-gray-200 tw-pt-10">
        <h3 class="tw-text-sm tw-font-medium tw-text-gray-900">Share</h3>
        <ul role="list" class="tw-mt-4 tw-flex tw-items-center tw-space-x-6">
          <li>
            <a [href]="'http://www.facebook.com/sharer/sharer.php?u=' + myLink + '&t=' + selectedPurchasable.data?.product?.data?.name"
              target="_blank"
              class="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-text-gray-400 hover:tw-text-gray-500">
              <span class="tw-sr-only">Share on Facebook</span>
              <svg class="tw-h-5 tw-w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                  clip-rule="evenodd" />
              </svg>
            </a>
          </li>
          <!-- <li>
              <a href="#" class="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-text-gray-400 hover:tw-text-gray-500">
                <span class="tw-sr-only">Share on Instagram</span>
                <svg class="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </li> -->
          <li>
            <a [href]="'https://twitter.com/intent/tweet?url=' + myLink + '&text=' + selectedPurchasable.data?.product?.data?.name"
              target="_blank"
              class="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-text-gray-400 hover:tw-text-gray-500">
              <span class="tw-sr-only">Share on Twitter</span>
              <svg class="tw-h-5 tw-w-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path
                  d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="tw-mx-auto tw-mt-16 tw-w-full tw-max-w-2xl lg:tw-col-span-4 lg:tw-mt-0 lg:tw-max-w-none">
      <sidkik-tabs data-testid="product-detail-tabs">
        <sidkik-tab data-testid="product-detail-description-tab" title="Description">
          <sidkik-editor-js-renderer class="tw-prose" [content]="selectedPurchasable?.data?.product?.data?.description">
          </sidkik-editor-js-renderer>
        </sidkik-tab>
        <sidkik-tab title="What is Included" data-testid="product-detail-what-is-included-tab">
          <sidkik-editor-js-renderer class="tw-prose"
            [content]="selectedPurchasable?.data?.product?.data?.whatIsIncluded">
          </sidkik-editor-js-renderer>
        </sidkik-tab>
      </sidkik-tabs>
    </div>

  </div>
</div>