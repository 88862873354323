import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ProductActions from './product.actions';
import { productStateKey } from '@sidkik/global';
import { ProductProperties } from '@sidkik/db';

export const productsFeatureKey = productStateKey;

export interface State extends EntityState<ProductProperties> {
  selectedId?: string | number;
  loaded: boolean;
  loading: boolean;
  error?: string | null;
}

export const adapter: EntityAdapter<ProductProperties> =
  createEntityAdapter<ProductProperties>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(ProductActions.loadProductsAndSelectProductSuccess, (state, action) =>
    adapter.setAll(action.products, {
      ...state,
      loaded: true,
      loading: false,
      selectedId: action.id,
    })
  ),
  on(ProductActions.selectProduct, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(ProductActions.loadProductsSuccess, (state, action) =>
    adapter.setAll(action.products, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(ProductActions.loadProductsFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error,
  })),
  on(ProductActions.loadProducts, (state) => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(ProductActions.updateProductIntegrations, (state, action) =>
    adapter.updateOne(action.product, state)
  ),
  on(ProductActions.addProduct, (state, action) =>
    adapter.addOne(action.product, state)
  ),
  on(ProductActions.updateProduct, (state, action) =>
    adapter.setOne(action.product, state)
  ),
  on(ProductActions.deleteProduct, (state, action) =>
    adapter.removeOne(action.product.id, state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
