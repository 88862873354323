import {
  ChangeDetectionStrategy,
  signal,
  Component,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import {
  CalendarOptions,
  DateSelectArg,
  DayCellContentArg,
  EventApi,
  EventClickArg,
  EventInput,
  MoreLinkAction,
  MoreLinkContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';

import { BehaviorSubject } from 'rxjs';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { DOCUMENT } from '@angular/common';

enum ViewType {
  dayGridMonth = 'dayGridMonth',
  timeGridWeek = 'timeGridWeek',
  timeGridDay = 'timeGridDay',
  listWeek = 'listWeek',
  listDay = 'listDay',
  listMonth = 'listMonth',
  listYear = 'listYear',
  dayGridDay = 'dayGridDay',
  multiMonthFourMonth = 'multiMonthFourMonth',
}

@Component({
  selector: 'sidkik-shop-calendar-view',
  templateUrl: './calendar-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarViewComponent implements OnInit, AfterViewInit {
  @Input() events: EventInput[] | undefined | null = [];
  @Output() eventSelected: EventEmitter<EventInput> = new EventEmitter();

  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;

  showSimpleSelector$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  views = ViewType;
  currentView$: BehaviorSubject<ViewType> = new BehaviorSubject<ViewType>(
    ViewType.multiMonthFourMonth
  );

  calendarVisible = signal(true);
  calendarOptions = signal<CalendarOptions>({
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      multiMonthPlugin,
    ],
    headerToolbar: false,
    initialView: 'multiMonthFourMonth',
    // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    views: {
      multiMonthFourMonth: {
        type: 'multiMonth',
        duration: { months: 3 },
        showNonCurrentDates: false,
      },
    },
    eventTimeFormat: {
      hour: 'numeric',
      minute: 'numeric',
      meridiem: 'narrow',
    },
    lazyFetching: true,
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,

    dateClick: this.handleDateClick.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
  });
  currentEvents = signal<EventApi[]>([]);
  events$: BehaviorSubject<EventInput[]> = new BehaviorSubject<EventInput[]>(
    []
  );

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleScreenSizeChange();
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit(): void {
    this.handleScreenSizeChange();
  }

  ngOnInit() {
    this.handleScreenSizeChange();
  }

  goBackToView(view: ViewType) {
    this.currentView$.next(view);
    if (this.calendarComponent) {
      this.calendarComponent.getApi().changeView(view);
    }
  }

  handleScreenSizeChange() {
    const screenWidth = this.document.body.clientWidth;
    if (screenWidth < 768) {
      this.showSimpleSelector$.next(true);
    }
    if (screenWidth >= 768) {
      this.showSimpleSelector$.next(false);
    }
  }

  handleCalendarToggle() {
    this.calendarVisible.update((bool) => !bool);
  }

  moreLinkClick(evt: MouseEvent) {
    const target = evt.target as HTMLElement;
    const dateValue = this.findParentWithDateAttribute(target); // YYYY-MM-DD
    if (this.calendarComponent && dateValue) {
      const listViewType = ViewType.listYear;
      const [year, month, day] = dateValue.split('-').map(Number);
      const viewStartDate = new Date(year, month - 1, day);
      this.changeViewToListAndScroll(listViewType, viewStartDate);
    }
  }

  findParentWithDateAttribute(element: HTMLElement): string | null {
    while (element) {
      if (element.hasAttribute('data-date')) {
        return element.getAttribute('data-date');
      }
      element = element.parentElement as HTMLElement;
    }
    return null;
  }

  changeViewToListAndScroll(viewType: ViewType, date: Date) {
    this.currentView$.next(viewType);
    if (!this.calendarComponent) {
      return;
    }
    this.calendarComponent.getApi().changeView(ViewType.listYear, date);
    const target = this.document.querySelector(
      `.fc-${viewType}-view .fc-list-day[data-date="${
        date.toISOString().split('T')[0]
      }"]`
    );
    if (target) {
      const scroller = this.document.querySelector(
        `.fc-${viewType}-view .fc-scroller`
      );
      if (scroller) {
        scroller.scrollTop = (target as HTMLElement).offsetTop;
      }
    }
  }

  handleDateClick(arg: any) {
    if (this.calendarComponent) {
      const listViewType = ViewType.listYear;
      const viewStartDate = new Date(arg.date);
      this.changeViewToListAndScroll(listViewType, viewStartDate);
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.eventSelected.emit(clickInfo.event.toPlainObject());
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents.set(events);
    this.changeDetector.detectChanges(); // workaround for pressionChangedAfterItHasBeenCheckedError
  }
}
