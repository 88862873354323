import { createAction, props } from '@ngrx/store';

import {
  loadProductsType,
  loadProductsFailureType,
  loadProductsSuccessType,
  noOperation,
  addProductFailureType,
  addProductSuccessType,
  addProductType,
  updateProductFailureType,
  updateProductSuccessType,
  updateProductType,
  deleteProductFailureType,
  deleteProductSuccessType,
  deleteProductType,
  loadProductsAndSelectProductFailureType,
  loadProductsAndSelectProductSuccessType,
  loadProductsAndSelectProductType,
  selectProductType,
  MonitorDocumentFailure,
  monitorDocumentFailureType,
  MonitorDocumentSuccess,
  monitorDocumentSuccessType,
  updateProductIntegrationsType,
  updateProductIntegrationsSuccessType,
  updateProductIntegrationsFailureType,
} from '@sidkik/global';
import { ProductProperties } from '@sidkik/db';
import { Update } from '@ngrx/entity';

export const noop = createAction(noOperation);

export const loadProducts = createAction(loadProductsType);

export const loadProductsSuccess = createAction(
  loadProductsSuccessType,
  props<{ products: ProductProperties[] }>()
);

export const loadProductsFailure = createAction(
  loadProductsFailureType,
  props<{ error: any }>()
);

export const loadProductsAndSelectProduct = createAction(
  loadProductsAndSelectProductType,
  props<{ id: string }>()
);

export const loadProductsAndSelectProductSuccess = createAction(
  loadProductsAndSelectProductSuccessType,
  props<{ products: ProductProperties[]; id: string }>()
);

export const loadProductsAndSelectProductFailure = createAction(
  loadProductsAndSelectProductFailureType,
  props<{ error: any; id: string }>()
);

export const selectProduct = createAction(
  selectProductType,
  props<{ id: string }>()
);

export const addProduct = createAction(
  addProductType,
  props<{ product: ProductProperties }>()
);

export const addProductSuccess = createAction(
  addProductSuccessType,
  props<{ product: ProductProperties }>()
);

export const addProductFailure = createAction(
  addProductFailureType,
  props<{ product: ProductProperties }>()
);

export const updateProduct = createAction(
  updateProductType,
  props<{ product: ProductProperties }>()
);

export const updateProductIntegrations = createAction(
  updateProductIntegrationsType,
  props<{ product: Update<ProductProperties> }>()
);

export const updateProductIntegrationsSuccess = createAction(
  updateProductIntegrationsSuccessType,
  props<{ update: Update<ProductProperties>; product: ProductProperties }>()
);

export const updateProductIntegrationsFailure = createAction(
  updateProductIntegrationsFailureType,
  props<{
    update: Update<ProductProperties>;
    error: any;
  }>()
);

export const updateProductSuccess = createAction(
  updateProductSuccessType,
  props<{ product: ProductProperties }>()
);

export const updateProductFailure = createAction(
  updateProductFailureType,
  props<{ product: ProductProperties; error: any }>()
);

export const deleteProduct = createAction(
  deleteProductType,
  props<{ product: ProductProperties }>()
);

export const deleteProductSuccess = createAction(deleteProductSuccessType);

export const deleteProductFailure = createAction(
  deleteProductFailureType,
  props<{ product: ProductProperties; error: any }>()
);

export const monitorDocumentSuccess = createAction(
  monitorDocumentSuccessType,
  props<MonitorDocumentSuccess>()
);

export const monitorDocumentFailure = createAction(
  monitorDocumentFailureType,
  props<MonitorDocumentFailure>()
);
