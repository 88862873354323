import { createAction, props } from '@ngrx/store';

import {
  loadMapsFailureType,
  loadMapsSuccessType,
  loadMapsType,
  selectMapType,
  noOperation,
} from '@sidkik/global';

import { MapProperties } from '@sidkik/db';

export const noop = createAction(noOperation);

export const loadMaps = createAction(loadMapsType);

export const loadMapsSuccess = createAction(
  loadMapsSuccessType,
  props<{ maps: MapProperties[] }>()
);

export const selectMap = createAction(selectMapType, props<{ id: string }>());

export const loadMapsFailure = createAction(
  loadMapsFailureType,
  props<{ error: any }>()
);
