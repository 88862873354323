import { ComboBoxOption } from '@sidkik/global';

export interface FormComponent {
  type: string;
  classes?: string[];
  attributes: {
    name: string;
    colspan: number;
    required: boolean;
    label: string;
    decoration: string;
    value: string;
    textSize: string;
    content: string;
    checked: boolean;
    [key: string]: string | boolean | number;
    align: string;
  };
  options?: ComboBoxOption[];
  components?: FormComponent[];
  activeOnRender?: number;
}

export interface Frame {
  component: FormComponent;
}

export interface Config {
  frames: Frame[];
}

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}
