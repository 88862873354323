import { createFeatureSelector, createSelector } from '@ngrx/store';
import { shopStateKey, productStateKey, UtilsService } from '@sidkik/global';
import { ComboBoxOption } from '@sidkik/global';
import { ShopState } from '../shop.reducer';
import { State, selectAll, selectEntities } from './product.reducer';

export const getShopState = createFeatureSelector<ShopState>(shopStateKey);

export const getState = createSelector(
  getShopState,
  (state: ShopState) => state[productStateKey]
);

export const getProductLoaded = createSelector(getState, (state: State) => {
  return state?.loaded;
});

export const getProductLoading = createSelector(getState, (state: State) => {
  return state?.loading;
});

export const getProductError = createSelector(
  getState,
  (state: State) => state.error
);

export const getAllProducts = createSelector(getState, (state: State) => {
  return selectAll(state);
});

export const getProductEntities = createSelector(getState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getProductEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getProductById = (id: string) =>
  createSelector(getProductEntities, (entities) => entities[id]);

export const getProductTags = createSelector(getAllProducts, (products) =>
  UtilsService.removeDuplicatesAndUndefined(
    (products.map((p) => p.data.tags) ?? []).flat()
  ).map((t) => ({ value: t, label: t } as ComboBoxOption))
);
