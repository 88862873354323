@if (renderable) {
<div class="tw-flex tw-justify-center" [class.content]="isLongForm" [class.read-more-target]="readMore">
  <div class="tw-flex tw-flex-col tw-w-full tw-justify-center tw-items-center">
    @for(b of renderable.blocks; track b.id) {
    @switch (b.type) {
    @case ('paragraph') {
    <div class="tw-w-full tw-prose">
      <sidkik-editor-js-paragraph [textVariant]="b.tunes?.textVariant ?? ''"
        [textAlignment]="b.tunes?.textAlignment?.alignment ?? 'left'" [content]="b.data">
      </sidkik-editor-js-paragraph>
    </div>
    }
    @case ('list') {
    <div class="tw-w-full tw-prose">
      <sidkik-editor-js-list [content]="b.data"></sidkik-editor-js-list>
    </div>
    }
    @case ('nestedList') {
    <div class="tw-w-full tw-prose">
      <sidkik-editor-js-nested-list [content]="b.data">
      </sidkik-editor-js-nested-list>
    </div>
    }
    @case ('header') {
    <div class="tw-font-headings tw-w-full tw-prose">
      <sidkik-editor-js-header [content]="b.data"
        [textAlignment]="b.tunes?.textAlignment?.alignment ?? 'left'"></sidkik-editor-js-header>
    </div>
    }
    @case ('image') {
    <sidkik-editor-js-image [content]="b.data"></sidkik-editor-js-image>
    }
    @case ('embed') {
    <div class="tw-w-full tw-prose">
      <sidkik-editor-js-embed [content]="b.data"></sidkik-editor-js-embed>
    </div>
    }
    @case ('code') {
    <div class="tw-w-full">
      <sidkik-editor-js-code [content]="b.data"></sidkik-editor-js-code>
    </div>
    }
    @case ('table') {
    <div class="tw-w-full tw-prose">
      <sidkik-editor-js-table [content]="b.data"></sidkik-editor-js-table>
    </div>
    }
    @case ('warning') {
    <div class="tw-w-full tw-max-w-prose">
      <sidkik-editor-js-warning [content]="b.data"></sidkik-editor-js-warning>
    </div>
    }
    @case ('quote') {
    <div class="tw-w-full tw-prose tw-max-w-prose">
      <sidkik-editor-js-quote [content]="b.data"></sidkik-editor-js-quote>
    </div>
    }
    @case ('delimiter') {
    <div class="tw-w-full tw-prose">
      <sidkik-editor-js-delimiter></sidkik-editor-js-delimiter>
    </div>
    }
    @case ('checklist') {
    <div class="tw-w-full tw-prose">
      <sidkik-editor-js-checklist [content]="b.data"></sidkik-editor-js-checklist>
    </div>
    }
    @case ('raw') {
    <div class="tw-w-full tw-prose">
      <sidkik-editor-js-raw-html [content]="b.data"></sidkik-editor-js-raw-html>
    </div>
    }
    @case ('form'){
    <div class="tw-w-full tw-prose" [innerHTML]="getFormInnerHtml(b.data.formId) | safeHtml">
    </div>
    }
    }
    }
  </div>
</div>
}