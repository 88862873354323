<div *ngrxLet="{items: cartItems$, subTotal: subTotal$} as cart"
  class="tw-mx-auto tw-max-w-2xl tw-px-4 tw-py-16 sm:tw-px-6 sm:tw-py-24 lg:tw-px-0">
  <h1 class="tw-text-center tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl tw-mb-12">
    Shopping Cart</h1>
  <section *ngIf="upsellContent$ | async as us" class="-tw-mb-5 tw-pb-4">
    <h2 class="tw-text-xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-2xl -tw-mb-3">{{us.title}}</h2>
    <div class="tw-mb-5">
      <sidkik-editor-js-renderer class="tw-prose" [content]="us.content">
      </sidkik-editor-js-renderer>
    </div>
    <ul role="list" class="tw-border-t tw-border-gray-200">
      <sidkik-upsell-item *ngFor="let item of upsellItems$ |async; index as i" [item]="item"
        (addItem)="addItemToCart($event)" [testIdPrefix]="'upsell-item-' + i"></sidkik-upsell-item>
    </ul>
    <h2
      class="tw-text-center tw-mt-10 marker:tw-text-xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-2xl tw-mb-12">
      Items in your cart</h2>
  </section>

  <div data-testid="cart-empty" *ngIf="cart.items.length <= 0" class="tw-text-center">
    <svg class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
      stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
    </svg>
    <h3 class="tw-mt-2 tw-text-sm tw-font-semibold tw-text-gray-900">No items in your cart yet.</h3>
    <p class="tw-mt-1 tw-text-sm tw-text-gray-500">Get started by picking a course or challenge for purchase.</p>
    <div class="tw-mt-6">
      <a [routerLink]="['/shop']" class="tw-font-medium tw-text-primary-600 hover:tw-text-primary-500">
        Continue Shopping
        <span aria-hidden="true"> &rarr;</span>
      </a>
    </div>
  </div>

  <section *ngIf="cart.items.length > 0" aria-labelledby="cart-heading">
    <h2 id="cart-heading" class="tw-sr-only">Items in your shopping cart</h2>

    <ul role="list" class="tw-border-t tw-border-gray-200">
      <sidkik-cart-item *ngFor="let item of cart.items; index as i" [cartItem]="item"
        (removeItem)="removeCartItem($event)" [testIdPrefix]="'cart-item-' + i"></sidkik-cart-item>
    </ul>
  </section>
  <section *ngIf="cart.items.length > 0" aria-labelledby="summary-heading" class="tw-mt-10">
    <h2 id="summary-heading" class="tw-sr-only">Order summary</h2>
    <div *ngIf="(validCart$ |async)?.ok">
      <dl class="tw-space-y-4">
        <div class="tw-flex tw-items-center tw-justify-between">
          <dt class="tw-text-base tw-font-medium tw-text-gray-900">Subtotal</dt>
          <dd class="tw-ml-4 tw-text-base tw-font-medium tw-text-gray-900" data-testid="cart-subtotal">
            {{((cart.subTotal)) * .01 | currency}}
          </dd>
        </div>
      </dl>
      <p class="tw-mt-1 tw-text-sm tw-text-gray-500">Taxes will be calculated at checkout. For subscription items,
        monthly or yearly, payment is due today and will follow the defined subscription payment schedule. Subscriptions
        are auto renewing and will be charged periodically until you cancel. </p>
    </div>
    <div *ngIf="!(validCart$ |async)?.ok">
      <p class="tw-mt-1 tw-text-sm tw-font-bold tw-text-error-500">{{ (validCart$ | async)?.error }}</p>
    </div>

    <div class="tw-mt-10">
      <button data-testid="checkout-link" [routerLink]="['/shop/checkout/steps']" *ngIf="(validCart$ |async)?.ok"
        type="button" class="btn-primary tw-w-full">Checkout</button>
    </div>

    <div class="tw-mt-6 tw-text-center tw-text-sm tw-text-gray-500">
      <p>
        or
        <a data-testid="continue-shopping-link" [routerLink]="['/shop']"
          class="tw-font-medium tw-text-primary-600 hover:tw-text-primary-500">
          Continue Shopping
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </p>
    </div>
  </section>
</div>