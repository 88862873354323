import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as SKUActions from './sku.actions';
import { skuStateKey } from '@sidkik/global';
import { SKUProperties } from '@sidkik/db';

export const skusFeatureKey = skuStateKey;

export interface State extends EntityState<SKUProperties> {
  selectedId?: string | number;
  loaded: boolean;
  loading: boolean;
  error?: string | null;
}

export const adapter: EntityAdapter<SKUProperties> =
  createEntityAdapter<SKUProperties>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(SKUActions.loadSKUsAndSelectSKUSuccess, (state, action) =>
    adapter.setAll(action.products, {
      ...state,
      loaded: true,
      loading: false,
      selectedId: action.id,
    })
  ),
  on(SKUActions.selectSKU, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(SKUActions.loadSKUsSuccess, (state, action) =>
    adapter.setAll(action.skus, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(SKUActions.loadSKUsFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error,
  })),
  on(SKUActions.loadSKUs, (state) => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(SKUActions.addSKU, (state, action) => adapter.addOne(action.sku, state)),
  on(SKUActions.updateSKU, (state, action) =>
    adapter.setOne(action.sku, state)
  ),
  on(SKUActions.updateSKUIntegrations, (state, action) =>
    adapter.updateOne(action.sku, state)
  ),
  on(SKUActions.deleteSKU, (state, action) =>
    adapter.removeOne(action.sku.id, state)
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
