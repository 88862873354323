<div class="tw-rounded-md tw-bg-info-50 tw-p-4">
  <div class="tw-flex">
    <div class="tw-flex-shrink-0">
      <svg class="tw-h-5 tw-w-5 tw-text-info-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
          clip-rule="evenodd" />
      </svg>
    </div>
    <div class="tw-ml-3">
      <h3 class="tw-text-sm tw-font-medium tw-text-info-800 tw-mt-0">{{content.title}}</h3>
      <div class="tw-mt-2 tw-text-sm tw-text-info-700">
        <p>{{content.message}}</p>
      </div>
    </div>
  </div>
</div>