import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType, OnInitEffects } from '@ngrx/effects';
import { fetch, pessimisticUpdate } from '@ngrx/router-store/data-persistence';
import {
  CustomerProperties,
  DbService,
  OrderRequest,
  OrderRequestProperties,
} from '@sidkik/db';
import {
  EntityType,
  IntegrationStatus,
  IntegrationStatusFinally,
  IntegrationStatusPendingOrFinally,
  PathsToMonitor,
  ShopError,
  SpanTypes,
  TraceService,
} from '@sidkik/global';
import { filter, map, take, tap, withLatestFrom } from 'rxjs';
import * as RequestActions from './request.actions';
import { RequestFacade } from './request.facade';
import { ShopFacade } from '../shop.facade';
import { NotificationService } from '@sidkik/ui';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class RequestEffects implements OnInitEffects {
  /** Navigation Actions */

  /** Fetch Actions */
  loadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.loadRequest),
      withLatestFrom(this.shopFacade.userId$),
      fetch({
        id: (
          a: ReturnType<typeof RequestActions.loadRequest>,
          userId: string
        ) => a.type + userId,
        run: (
          a: ReturnType<typeof RequestActions.loadRequest>,
          userId: string
        ) => {
          return this.dbService
            .getAllSubEntities<OrderRequest>(
              EntityType.OrderRequest,
              EntityType.CustomerParent,
              userId
            )
            .pipe(
              map((docs) =>
                RequestActions.loadRequestSuccess({ requests: docs })
              )
            );
        },
        onError: (_, error) => {
          return null;
        },
      })
    )
  );

  /** Persistance Actions */

  addRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.addRequest),
      withLatestFrom(this.shopFacade.userId$, this.shopFacade.me$),
      pessimisticUpdate({
        run: (
          a: ReturnType<typeof RequestActions.addRequest>,
          userId: string | undefined,
          me: CustomerProperties | undefined
        ) => {
          if (!me) {
            throw new ShopError(
              'unable to load information for customer',
              'Unable to purchase'
            );
          }
          if (!userId) {
            throw new ShopError(
              'unable to load information for customer',
              'Unable to purchase'
            );
          }
          if (userId !== me.id) {
            throw new ShopError(
              'customer information does not match order',
              'Unable to purchase'
            );
          }
          // add monitor
          if (
            a.request.integrations.state.status === IntegrationStatus.resync
          ) {
            this.dbService.monitorDocument(
              EntityType.OrderRequest,
              a.request.id,
              PathsToMonitor.IntegrationStatus,
              IntegrationStatusPendingOrFinally,
              a.request.integrations.state.lastSync ?? 0,
              PathsToMonitor.IntegrationUpdatedField,
              60000,
              EntityType.CustomerParent,
              userId
            );
          }
          return this.dbService
            .setDoc(a.request, EntityType.CustomerParent, userId)
            .pipe(
              map(() =>
                RequestActions.addRequestSuccess({
                  request: a.request,
                })
              ),
              tap(() => {
                this.traceService.endSpan(SpanTypes.shopStoreOrderRequest);
                this.traceService.startChildSpan(
                  SpanTypes.shopMonitorOrderRequest,
                  SpanTypes.shopProcessOrder
                );
              })
            );
        },
        onError: (a: ReturnType<typeof RequestActions.addRequest>, error) => {
          this.traceService.endSpan(SpanTypes.shopStoreOrderRequest, error);
          this.traceService.endSpan(SpanTypes.shopProcessOrder, error);
          return RequestActions.addRequestFailure({
            request: a.request,
            error: error.message,
          });
        },
      })
    )
  );

  deleteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.deleteRequest),
      withLatestFrom(this.shopFacade.userId$),
      pessimisticUpdate({
        run: (
          a: ReturnType<typeof RequestActions.deleteRequest>,
          userId: string | undefined
        ) => {
          if (userId) {
            return this.dbService
              .deleteDoc(a.request, EntityType.CustomerParent, userId)
              .pipe(map(() => RequestActions.deleteRequestSuccess()));
          }
          return this.dbService
            .deleteDocLocalOnly(a.request)
            .pipe(map(() => RequestActions.deleteRequestSuccess()));
        },
        onError: (
          a: ReturnType<typeof RequestActions.deleteRequest>,
          error
        ) => {
          return RequestActions.deleteRequestFailure({
            request: a.request,
            error: error.message,
          });
        },
      })
    )
  );

  addRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequestActions.addRequestSuccess),
        tap((a: ReturnType<typeof RequestActions.addRequestSuccess>) => {
          // assumes coming from inside the products base
          // this.router.navigate(['shop', 'request']);
        })
      ),
    { dispatch: false }
  );

  addRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RequestActions.addRequestFailure),
        tap((a: ReturnType<typeof RequestActions.addRequestFailure>) => {
          this.notificationService.showError(
            'Order Processing Failure',
            'We were unable to process your order. This page will refresh in a few seconds. Please try to process again.'
          );
          setTimeout(() => this.document.location.reload(), 5000);
        })
      ),
    { dispatch: false }
  );

  /** General Actions */

  /** Listen for other items */

  monitorDocumentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.monitorDocumentSuccess),
      filter(
        (a: ReturnType<typeof RequestActions.monitorDocumentSuccess>) =>
          a.entityType === EntityType.OrderRequest
      ),
      map((a) => {
        switch (a.pathToMonitor) {
          case PathsToMonitor.IntegrationStatus: {
            const request = a.data as OrderRequestProperties;
            this.traceService.endSpan(SpanTypes.shopMonitorOrderRequest);

            return RequestActions.attemptPayment({
              request: {
                id: request.id,
                changes: { ...request },
              },
            });
          }
          default:
            return RequestActions.noop();
        }
      })
    )
  );

  monitorDocumentFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RequestActions.monitorDocumentFailure),
      filter(
        (a: ReturnType<typeof RequestActions.monitorDocumentFailure>) =>
          a.entityType === EntityType.OrderRequest
      ),
      tap(() => {
        this.notificationService.showError(
          'Issue processing your order',
          "We didn't get a response regarding your order. Payment was not processed. You can try to submit your order again. If unable to submit, please refresh the page and try again."
        );
      }),
      map(() => RequestActions.clearTimeoutError())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly requestFacade: RequestFacade,
    private readonly shopFacade: ShopFacade,
    private readonly dbService: DbService,
    private readonly traceService: TraceService,
    private readonly notificationService: NotificationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngrxOnInitEffects() {
    this.shopFacade.me$
      .pipe(
        filter((me) => !!me),
        take(1)
      )
      .subscribe((me) => {
        logger.info(
          'shop:request.effects:init',
          'loading order requests for ',
          me.id
        );
        this.requestFacade.loadRequest();
      });
    return RequestActions.noop();
  }
}
