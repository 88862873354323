import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, combineReducers } from '@ngrx/store';
import { shopStateKey } from '@sidkik/global';

import * as fromProduct from './product/product.reducer';
import * as fromSku from './sku/sku.reducer';
import * as fromCart from './cart/cart.reducer';
import * as fromRequest from './request/request.reducer';
import * as fromMap from './map/map.reducer';

export const SHOP_FEATURE_KEY = shopStateKey;

export interface ShopState extends EntityState<unknown> {
  [fromProduct.productsFeatureKey]: fromProduct.State;
  [fromSku.skusFeatureKey]: fromSku.State;
  [fromCart.cartFeatureKey]: fromCart.State;
  [fromRequest.requestFeatureKey]: fromRequest.State;
  [fromMap.mapsFeatureKey]: fromMap.State;
}

export interface ShopPartialState {
  readonly [SHOP_FEATURE_KEY]: ShopState;
}

export const shopAdapter: EntityAdapter<unknown> =
  createEntityAdapter<unknown>();

export const initialShopState: ShopState = shopAdapter.getInitialState({
  [fromProduct.productsFeatureKey]: fromProduct.adapter.getInitialState(
    fromProduct.initialState
  ),
  [fromSku.skusFeatureKey]: fromSku.adapter.getInitialState(
    fromSku.initialState
  ),
  [fromCart.cartFeatureKey]: fromCart.adapter.getInitialState(
    fromCart.initialState
  ),
  [fromRequest.requestFeatureKey]: fromRequest.adapter.getInitialState(
    fromRequest.initialState
  ),
  [fromMap.mapsFeatureKey]: fromMap.adapter.getInitialState(
    fromMap.initialState
  ),
});

const reducers = combineReducers({
  [fromProduct.productsFeatureKey]: fromProduct.reducer,
  [fromSku.skusFeatureKey]: fromSku.reducer,
  [fromCart.cartFeatureKey]: fromCart.reducer,
  [fromRequest.requestFeatureKey]: fromRequest.reducer,
  [fromMap.mapsFeatureKey]: fromMap.reducer,
});

export function shopReducer(state: ShopState | undefined, action: Action) {
  return reducers(state, action);
}
