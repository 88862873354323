/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Purchasable } from '@sidkik/db';

@Component({
  selector: 'sidkik-product-card',
  templateUrl: './product-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent {
  @Input() purchasable!: Partial<Purchasable>;
  @Input() testIdPrefix = '';
}
