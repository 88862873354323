<li class="tw-flex tw-py-6 tw-border-b tw-border-gray-200">
  <div class="tw-flex-shrink-0">
    <sidkik-asset [attr.data-testid]="testIdPrefix + '-image'" [asset]="item?.data?.product?.data?.image"
      class="!tw-relative tw-block tw-h-24 tw-w-24 tw-rounded-md tw-object-cover tw-object-center sm:tw-h-32 sm:tw-w-32"
      preset="med_square"></sidkik-asset>
  </div>

  <div class="tw-ml-4 tw-flex tw-flex-1 sm:tw-ml-6">
    <div class="tw-flex-grow">
      <div class="tw-flex tw-justify-between">
        <h4 class="tw-text-sm">
          <span [attr.data-testid]="testIdPrefix + '-name'"
            class="tw-font-medium tw-text-gray-700 hover:tw-text-gray-800">{{
            item?.data?.product?.data?.name}}</span>
        </h4>
      </div>
      <p [attr.data-testid]="testIdPrefix + '-caption'" class="tw-mt-1 tw-text-sm tw-text-gray-500">{{
        item?.data?.product?.data?.caption}}</p>
    </div>

    <div class="tw-flex tw-flex-shrink tw-flex-col tw-flex-1 tw-gap-2">
      <button [attr.data-testid]="'product-detail-pay-button-' + i" *ngFor="let sku of item?.data?.sku; index as i"
        type="button" class="btn-sub-primary" (click)="addItemToCart(item, sku)">Add
        {{sku.data.price * 0.01| currency}}<span class="tw-font-normal tw-text-xs"
          *ngIf="sku.data?.type === 'recurring'">/{{sku.data.recurring?.period}}</span></button>
    </div>
  </div>
</li>