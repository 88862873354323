import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as SKUSelectors from './sku.selectors';
import * as SKUActions from './sku.actions';
import { SKU, SKUProperties, StorageFactory } from '@sidkik/db';
import { take } from 'rxjs';

@Injectable()
export class SKUFacade {
  loaded$ = this.store.pipe(select(SKUSelectors.getSKULoaded));
  loading$ = this.store.pipe(select(SKUSelectors.getSKULoading));
  allSKUs$ = this.store.pipe(select(SKUSelectors.getAllSKUs));
  selectedSKU$ = this.store.pipe(select(SKUSelectors.getSelected));

  constructor(private readonly store: Store) {}

  addSKU(productId: string) {
    this.store.dispatch(
      SKUActions.addSKU({
        sku: StorageFactory.getFactory(SKU, {
          data: { product: productId },
        }).toStorage(),
      })
    );
  }

  updateSKU(sku: SKU) {
    this.store.dispatch(SKUActions.updateSKU({ sku: sku.toStorage() }));
  }

  deleteSKU(sku: SKU) {
    const skuProperties = sku.toStorage ? sku.toStorage() : sku;
    this.store.dispatch(
      SKUActions.deleteSKU({
        sku: skuProperties as SKUProperties,
      })
    );
  }

  getSKU(id: string) {
    return this.store.pipe(select(SKUSelectors.getSKUById(id)), take(1));
  }
}
