import {
  Component,
  ChangeDetectionStrategy,
  Input,
  SimpleChange,
  OnChanges,
} from '@angular/core';
import { UtilsService } from '@sidkik/global';

interface EditorJSBlockContent {
  time: number;
  blocks: {
    type: string;
    id: string;
    data: any;
    tunes?: {
      textVariant: string;
      textAlignment?: { alignment: string };
    };
  }[];
}

interface Legacy {
  ops: any[];
}

@Component({
  selector: 'sidkik-editor-js-renderer',
  templateUrl: './editor-js-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsRendererComponent implements OnChanges {
  @Input() content: string | undefined;
  @Input() trackScroll = false;
  @Input() trackContentName!: string;
  @Input() trackContentPiece!: string;
  @Input() readMore = false;
  @Input() isLongForm = true;
  renderable!: EditorJSBlockContent;
  legacy!: Legacy;

  ngOnChanges(changes: { content: SimpleChange }) {
    if (changes?.content?.currentValue) {
      this.renderable = UtilsService.convertContentToRenderable(
        changes?.content?.currentValue
      );
    }
  }

  getFormInnerHtml(formId: string) {
    if (!formId) {
      return '';
    }
    return `<sidkik-dynamic-post-form data-form-id="${formId}"></sidkik-dynamic-post-form>`;
  }
}
