import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch, navigation } from '@ngrx/router-store/data-persistence';
import { ProductProperties, DbService } from '@sidkik/db';
import { EntityType } from '@sidkik/global';
import { map, withLatestFrom } from 'rxjs';
import { ProductListComponent } from '../../components/product/product-list/product-list.component';
import * as ProductActions from './product.actions';
import { ProductFacade } from './product.facade';
import { ProductDetailComponent } from '../../components/product/product-detail/product-detail.component';
import { CartListComponent } from '../../components/cart/cart-list/cart-list.component';

@Injectable()
export class ProductEffects {
  /** Navigation Actions */
  navigateToList$ = createEffect(() =>
    this.actions$.pipe(
      withLatestFrom(this.productFacade.loaded$, this.productFacade.loading$),
      navigation(ProductListComponent, {
        run: (
          route: ActivatedRouteSnapshot,
          loaded: boolean,
          loading: boolean
        ) => {
          if (!loaded && !loading) {
            return ProductActions.loadProducts();
          }
          return ProductActions.noop();
        },
        onError(route: ActivatedRouteSnapshot, error: any) {
          return null;
        },
      })
    )
  );

  navigateToDetails$ = createEffect(() =>
    this.actions$.pipe(
      withLatestFrom(this.productFacade.loaded$, this.productFacade.loading$),
      navigation(ProductDetailComponent, {
        run: (
          route: ActivatedRouteSnapshot,
          loaded: boolean,
          loading: boolean
        ) => {
          if (!loaded && !loading) {
            return ProductActions.loadProductsAndSelectProduct({
              id: route.params['id'],
            });
          }
          return ProductActions.selectProduct({ id: route.params['id'] });
        },
        onError(route: ActivatedRouteSnapshot, error: any) {
          return null;
        },
      })
    )
  );

  navigateToShoppingCart$ = createEffect(() =>
    this.actions$.pipe(
      withLatestFrom(this.productFacade.loaded$, this.productFacade.loading$),
      navigation(CartListComponent, {
        run: (
          route: ActivatedRouteSnapshot,
          loaded: boolean,
          loading: boolean
        ) => {
          if (!loaded && !loading) {
            return ProductActions.loadProducts();
          }
          return ProductActions.noop();
        },
        onError(route: ActivatedRouteSnapshot, error: any) {
          return null;
        },
      })
    )
  );

  /** Fetch Actions */
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadProducts),
      fetch({
        id: (a: ReturnType<typeof ProductActions.loadProducts>) => a.type,
        run: (a: ReturnType<typeof ProductActions.loadProducts>) => {
          return this.dbService
            .getAllEntities<ProductProperties>(EntityType.Product)
            .pipe(
              map((docs) =>
                ProductActions.loadProductsSuccess({ products: docs })
              )
            );
        },
        onError: (_, error) => {
          return null;
        },
      })
    )
  );

  loadProductsAndSelectProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadProductsAndSelectProduct),
      fetch({
        id: (
          a: ReturnType<typeof ProductActions.loadProductsAndSelectProduct>
        ) => a.type + a.id,
        run: (
          a: ReturnType<typeof ProductActions.loadProductsAndSelectProduct>
        ) => {
          return this.dbService
            .getAllEntities<ProductProperties>(EntityType.Product)
            .pipe(
              map((docs) =>
                ProductActions.loadProductsAndSelectProductSuccess({
                  products: docs,
                  id: a.id,
                })
              )
            );
        },
        onError: (_, error) => {
          return null;
        },
      })
    )
  );

  /** Persistance Actions */

  /** General Actions */

  /** Listen for other items */

  constructor(
    private readonly actions$: Actions,
    private readonly productFacade: ProductFacade,
    private readonly dbService: DbService
  ) {}
}
