import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductListComponent } from './components/product/product-list/product-list.component';
import { ProductDetailComponent } from './components/product/product-detail/product-detail.component';
import { CartListComponent } from './components/cart/cart-list/cart-list.component';
import { CheckoutStepsComponent } from './components/checkout/checkout-steps/checkout-steps.component';
import { canActivateAuthenticated } from '@sidkik/authz';
import { ProductGuard } from './guards/product.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'products/list',
    pathMatch: 'full',
  },
  {
    path: 'cart',
    component: CartListComponent,
  },
  {
    path: 'checkout/steps',
    component: CheckoutStepsComponent,
    canActivate: [canActivateAuthenticated],
  },
  {
    canLoad: [ProductGuard],
    canActivate: [ProductGuard],
    path: 'products/list',
    component: ProductListComponent,
  },
  {
    canLoad: [ProductGuard],
    canActivate: [ProductGuard],
    path: 'products/:id',
    component: ProductDetailComponent,
  },
  {
    path: '**',
    redirectTo: 'products/list',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RoutingModule {}
