import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CartItemProperties,
  CouponProperties,
  PromoCodeProperties,
} from '@sidkik/db';

@Component({
  selector: 'sidkik-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutSummaryComponent implements OnChanges {
  @Input() cartItems: (Partial<CartItemProperties> | undefined)[] | null = [];
  @Input() promoCode: PromoCodeProperties | undefined | null;
  @Input() coupon: CouponProperties | undefined | null;
  @Input() total = 0;
  @Input() subTotal = 0;
  @Input() discounts = 0;
  @Output() removeItem: EventEmitter<Partial<CartItemProperties>> =
    new EventEmitter<Partial<CartItemProperties>>();

  code!: string;
  couponName!: string;

  currentCartItems!: CartItemProperties[];
  currentCoupon!: CouponProperties;

  ngOnChanges(changes: SimpleChanges): void {
    const { promoCode, coupon } = changes;
    if (promoCode && promoCode.currentValue) {
      this.code = (promoCode.currentValue as PromoCodeProperties).data.code;
    }
    if (coupon && coupon.currentValue) {
      this.couponName = (coupon.currentValue as CouponProperties).data.name;
    }
  }

  removeCartItem(item: Partial<CartItemProperties>): void {
    if (!item) {
      return;
    }
    this.removeItem.emit(item);
  }
}
