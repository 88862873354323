import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

export interface HeaderBlock {
  text: string;
  level: number;
}

@Component({
  selector: 'sidkik-editor-js-header',
  templateUrl: './editor-js-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorJsHeaderComponent {
  @Input() content!: HeaderBlock;
  @Input() textAlignment!: string;
}
