<div id="order-summary" class="tw-mx-auto tw-max-w-2xl tw-px-4 lg:tw-max-w-none lg:tw-px-0">
  <h2 id="summary-heading" class="tw-sr-only">Order summary</h2>
  <dl>
    <dt class="tw-text-sm tw-font-medium">Amount due</dt>
    <dd class="tw-mt-1 tw-text-3xl tw-font-bold tw-tracking-tight" data-testid="cart-amount-due">{{((total)) * .01 |
      currency}}</dd>
  </dl>
  <ul role="list" class="tw-divide-y tw-divide-white tw-divide-opacity-10 tw-text-sm tw-font-medium">
    <sidkik-cart-item *ngFor="let item of cartItems; index as i" [cartItem]="item" (removeItem)="removeCartItem($event)"
      [testIdPrefix]="'cart-item-' + i"></sidkik-cart-item>
  </ul>
  <div class="tw-flex-none tw-mt-5">
    <sidkik-discount-form [cartItems]="cartItems"></sidkik-discount-form>
    <dl class="tw-mt-10 tw-space-y-6 tw-text-sm tw-font-medium tw-text-gray-500">
      <div class="tw-flex tw-justify-between">
        <dt>Subtotal</dt>
        <dd class="tw-text-gray-900" data-testid="cart-subtotal">{{((subTotal)) * .01 | currency}}</dd>
      </div>
      @if (code && discounts && discounts > 0){
      <div class="tw-flex tw-justify-between">
        <dt class="tw-flex">
          Discount
          <span data-testid="cart-discount-code"
            class="tw-ml-2 tw-rounded-full tw-bg-gray-200 tw-px-2 tw-py-0.5 tw-text-xs tw-tracking-wide tw-text-gray-600">{{code}}</span>
        </dt>
        <dd class="tw-text-gray-900" data-testid="cart-discount-amount">-{{((discounts)) * .01 | currency}}</dd>
      </div>
      }
      @if (coupon && coupon.data.description && coupon.data.description !== ''){
      <div class="tw-flex tw-justify-between !tw-mt-2">
        <dt class="tw-flex tw-text-xs tw-text-gray-400">
          {{coupon.data.description}}
        </dt>
        <dd class="tw-text-gray-900"></dd>
      </div>
      }
      <div class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 tw-pt-6 tw-text-gray-900">
        <dt>Total</dt>
        <dd class="tw-text-base" data-testid="cart-total">{{((total)) * .01 | currency}}</dd>
      </div>
    </dl>
  </div>
</div>