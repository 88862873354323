<ng-container>
  <div class="cdx-nested-list-wrapper" *ngIf="content.style === 'ordered'">
    <ol
      [ngClass]="['cdx-block', 'cdx-nested-list', 'cdx-nested-list--' + content.style, 'cdx-nested-list--' + content.style + '-1']">
      <li class="cdx-nested-list__item" *ngFor="let i of content.items">
        <div class="cdx-nested-list__item-body">
          <div class="cdx-nested-list__item-content" [innerHTML]="i.content"></div>
          <ol *ngIf="i.items"
            [ngClass]="['cdx-nested-list__item-children', 'cdx-nested-list', 'cdx-nested-list--' + content.style, 'cdx-nested-list--' + content.style + '-2']">
            <li class="cdx-nested-list__item" *ngFor="let j of i.items">
              <div class="cdx-nested-list__item-body">
                <div class="cdx-nested-list__item-content" [innerHTML]="j.content"></div>
                <ol *ngIf="j.items"
                  [ngClass]="['cdx-nested-list__item-children', 'cdx-nested-list', 'cdx-nested-list--' + content.style, 'cdx-nested-list--' + content.style + '-3']">
                  <li class="cdx-nested-list__item" *ngFor="let k of j.items">
                    <div class="cdx-nested-list__item-body">
                      <div class="cdx-nested-list__item-content" [innerHTML]="k.content"></div>
                    </div>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </div>
      </li>
    </ol>
  </div>
  <div class="cdx-nested-list-wrapper" *ngIf="content.style === 'unordered'">
    <ul
      [ngClass]="['cdx-block', 'cdx-nested-list', 'cdx-nested-list--' + content.style, 'cdx-nested-list--' + content.style + '-1']">
      <li class="cdx-nested-list__item" *ngFor="let i of content.items">
        <div class="cdx-nested-list__item-body">
          <div class="cdx-nested-list__item-content" [innerHTML]="i.content"></div>
          <ul *ngIf="i.items"
            [ngClass]="['cdx-nested-list__item-children', 'cdx-nested-list', 'cdx-nested-list--' + content.style, 'cdx-nested-list--' + content.style + '-2']">
            <li class="cdx-nested-list__item" *ngFor="let j of i.items">
              <div class="cdx-nested-list__item-body">
                <div class="cdx-nested-list__item-content" [innerHTML]="j.content"></div>
                <ul *ngIf="j.items"
                  [ngClass]="['cdx-nested-list__item-children', 'cdx-nested-list', 'cdx-nested-list--' + content.style, 'cdx-nested-list--' + content.style + '-3']">
                  <li class="cdx-nested-list__item" *ngFor="let k of j.items">
                    <div class="cdx-nested-list__item-body">
                      <div class="cdx-nested-list__item-content" [innerHTML]="k.content"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</ng-container>