<ng-container [ngSwitch]="textVariant">
  <div *ngSwitchCase="'call-out'" class="tw-rounded-md tw-bg-warn-50 tw-p-4">
    <div class="tw-flex">
      <div class="tw-flex-shrink-0">
        <svg class="tw-h-5 tw-w-5 tw-text-warn-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd"
            d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd" />
        </svg>
      </div>
      <div class="tw-ml-3">
        <div class="tw-text-sm tw-text-warn-700" [class.tw-text-right]="textAlignment === 'right'"
          [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
          [innerHTML]="content.text">
        </div>
      </div>
    </div>
  </div>
  <blockquote *ngSwitchCase="'citation'" class="" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
    [innerHTML]="content.text"></blockquote>
  <p *ngSwitchCase="'details'" class="tw-text-sm" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'"
    [innerHTML]="content.text"></p>
  <p *ngSwitchDefault="" style="margin-bottom: 0;" [class.tw-text-right]="textAlignment === 'right'"
    [class.tw-text-center]="textAlignment === 'center'" [class.tw-text-left]="textAlignment === 'left'" class="tw-mb-0"
    [innerHTML]="content.text"></p>
</ng-container>